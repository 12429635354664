<!-- Start Footer Area -->
<footer class="footer-area ptb-100 pb-0 bg-image">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-6">
                <div class="single-footer-widget">
                    <a class="logo"><img src="assets/img/logo.png" alt="logo2"></a>
                    <p>CREED can easily be accessed from any device and from anywhere because the Designs are extremely responsive.</p>

                    <ul class="social-list">
                        <li><a href="https://facebook.com/creedcms" target="_blank"><i class="icofont-facebook"></i></a></li>
                        <li><a href="https://twitter.com/creedcms" target="_blank"><i class="icofont-twitter"></i></a></li>
                        <li><a href="https://instagram.com/creedcms" target="_blank"><i class="icofont-instagram"></i></a></li>
                        <li><a href="https://linkedin.com/creedcms" target="_blank"><i class="icofont-linkedin"></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-footer-widget pl-5">
                    <h3>Resources</h3>

                    <ul class="list">
                        <li><a [routerLink]="['/features']" routerLinkActive="active">Features</a></li>
                        <li><a [routerLink]="['/home']" routerLinkActive="active">Church</a></li>
                        <li><a [routerLink]="['/home']" routerLinkActive="active">Fund Raising</a></li>
                        <!-- <li><a [routerLink]="['/utility']" routerLinkActive="active">Pay Utility</a></li> -->
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-footer-widget">
                    <h3>Policies</h3>

                    <ul class="list">
                        <li><a [routerLink]="['/privacy-policy']" routerLinkActive="active">Privacy Policy</a></li>
                        <li><a [routerLink]="['/terms-and-conditions']" routerLinkActive="active">Terms and Conditions</a></li>
                        <li><a [routerLink]="['/cookie-policy']" routerLinkActive="active">Cookie Policy</a></li>
                        <!-- <li><a href="#">Documentation</a></li> -->
                    </ul>
                    <!-- <ul class="list">
                        <li><a href="#">USSD</a></li>
                        <li><a href="#">API</a></li>
                        <li><a href="#">Chatbot</a></li>
                        <li><a href="#">Documentation</a></li>
                    </ul> -->
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-footer-widget">
                    <h3>Support</h3>

                    <ul class="list">
                        <li><a [routerLink]="['/about-us']" routerLinkActive="active">About</a></li>
                        <li><a [routerLink]="['/contact-us']" routerLinkActive="active">Contact</a></li>
                        <li><a [routerLink]="['/faqs']" routerLinkActive="active">FAQ</a></li>
                        <!-- <li><a [routerLink]="['/services']" routerLinkActive="active">Services</a></li> -->
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="copyright-area">
        <div class="container">
            <div class="row h-100 justify-content-center align-items-center">
                <div class="col-lg-6 col-md-6">
                    <p>&copy; 2020 Creed. All rights reserved Powered by <a href="http://acyst.tech">Acyst Technology</a></p>
                </div>

                <div class="col-lg-6 col-md-6">
                    <ul>
                        <li><a [routerLink]="['/terms_and_conditions']" routerLinkActive="active">Terms & Conditions</a></li>
                        <li><a [routerLink]="['/privacy_policy']" routerLinkActive="active">Privacy Policy</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</footer>
<!-- End Footer Area -->

<!-- Back Top top -->
<div class="back-to-top">Top</div>
<!-- End Back Top top -->