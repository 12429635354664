import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { SeoService } from 'src/app/services/seo.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent implements OnInit {

  constructor(private seo: SeoService) { 
    this.seo.generateTags({
      title: 'About Us - CREED Church Management System',
      description: environment.seo.description,
      image: environment.seo.imageurl,
      slug: 'about-us'
    });
  }

    ngOnInit() {
    }
}
