import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SeoService {

  constructor(private meta: Meta, private title: Title) { }

  generateTags(seo) {
    const config = {
      title: 'Creed Church Management System',
      description: environment.seo.description,
      image: environment.seo.imageurl,
      slug: window.location.pathname,
      ...seo
    }
    console.log(config);

    this.title.setTitle(config.title);
    
    // this.meta.updateTag({name: 'twitter:card', content: 'summary'});
    // this.meta.updateTag({name: 'twitter:site', content: 'Creed CMS'});
    this.meta.updateTag({name: 'title', content: config.title});
    this.meta.updateTag({name: 'description', content: config.description});
    this.meta.updateTag({name: 'image', content: config.image});
    this.meta.updateTag({rel: 'canonical', content: `https//creed-cms.com${window.location.pathname}`});

    this.meta.updateTag({name: 'twitter:card', content: 'summary'});
    this.meta.updateTag({name: 'twitter:site', content: 'Creed CMS'});
    this.meta.updateTag({name: 'twitter:title', content: config.title});
    this.meta.updateTag({name: 'twitter:description', content: config.description});
    this.meta.updateTag({name: 'twitter:image', content: config.image});

    this.meta.updateTag({property: 'og:type', content: 'article'});
    this.meta.updateTag({property: 'og:site_name', content: 'Creed CMS'});
    this.meta.updateTag({property: 'og:title', content: config.title});
    this.meta.updateTag({property: 'og:description', content: config.description});
    this.meta.updateTag({property: 'og:image', content: config.image});
    // this.meta.addTag({property: 'og:url', content: `https//creed-cms.com/${config.slug}`});
    this.meta.updateTag({property: 'og:url', content: `https//creed-cms.com${window.location.pathname}`});
  }
}
