<!-- Start Partner Area -->
<div class="partner-area">
    <div class="container">
        <div class="row">
            <div class="partner-slides owl-carousel owl-theme">
                <div class="col-lg-12">
                    <div class="item">
                        <a href="https://acyst.tech" target="_blank"><img src="assets/img/acyst-logo.png" alt="partner"></a>
                    </div>
                </div>

                <div class="col-lg-12">
                    <div class="item">
                        <a href="#"><img src="assets/img/partner-2.png" alt="partner"></a>
                    </div>
                </div>

                <!-- <div class="col-lg-12">
                    <div class="item">
                        <a href="#"><img src="assets/img/partner-3.png" alt="partner"></a>
                    </div>
                </div>

                <div class="col-lg-12">
                    <div class="item">
                        <a href="#"><img src="assets/img/partner-4.png" alt="partner"></a>
                    </div>
                </div>

                <div class="col-lg-12">
                    <div class="item">
                        <a href="#"><img src="assets/img/partner-5.png" alt="partner"></a>
                    </div>
                </div>

                <div class="col-lg-12">
                    <div class="item">
                        <a href="#"><img src="assets/img/partner-6.png" alt="partner"></a>
                    </div>
                </div>

                <div class="col-lg-12">
                    <div class="item">
                        <a href="#"><img src="assets/img/partner-7.png" alt="partner"></a>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</div>
<!-- End Partner Area -->
