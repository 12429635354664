import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { SeoService } from 'src/app/services/seo.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {

    constructor(private seo: SeoService) {
      this.seo.generateTags({
        title: 'Get Started - CREED Church Management System',
        description: environment.seo.description,
        image: environment.seo.imageurl,
        slug: 'signup'
      });
    }

    ngOnInit() {
    }

    sigup() {
      alert('pls call 0502666774 to get your church on board');
    }
}
