// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // website: 'https://localhost:4200/',
  app: 'https://demo.creedcms.com/',
  apiUrl: 'https://api-demo.creedcms.com/',
  imageUrl: 'https://api-demo.creedcms.com/Files/',
  // app: 'https://creedcms.com/',
  // website: 'https://creedcms.com/',
  // apiUrl: 'https://api.creedcms.com/',
  // imageUrl: 'https://api.creedcms.com/Files/',
  paystack: 'pk_test_953ef886394f01e4067cc079c82e13c14d8ea782',
  firebase: {
    apiKey: "AIzaSyDMrUNp-Yx6Dafb2xUnm5p-ir0tGEmhpYY",
    authDomain: "creed-e9329.firebaseapp.com",
    databaseURL: "https://creed-e9329.firebaseio.com",
    projectId: "creed-e9329",
    storageBucket: "creed-e9329.appspot.com",
    messagingSenderId: "308360367656",
    appId: "1:308360367656:web:168a52a9de7a9c652c2f1d",
    measurementId: "G-9QGL9TBBB7"
  },
  cookie_policy: {
    cookie: {
      domain: window.location.hostname // or 'your.domain.com' // it is mandatory to set a domain, for cookies to work properly (see https://goo.gl/S2Hy2A)
    },
    palette: {
      popup: {
        background: '#000'
      },
      button: {
        background: '#9a0002'
      }
    },
    theme: 'edgeless',
    type: 'opt-out',
    layout: 'my-custom-layout',
    layouts: {
      "my-custom-layout": '{{messagelink}}{{compliance}}'
    },
    elements:{
      messagelink: `
      <span id="cookieconsent:desc" class="cc-message">{{message}} 
        <a aria-label="learn more about cookies" tabindex="0" class="cc-link" href="{{cookiePolicyHref}}" target="_blank">{{cookiePolicyLink}}</a>, 
        <a aria-label="learn more about our privacy policy" tabindex="1" class="cc-link" href="{{privacyPolicyHref}}" target="_blank">{{privacyPolicyLink}}</a> and our 
        <a aria-label="learn more about our terms of service" tabindex="2" class="cc-link" href="{{tosHref}}" target="_blank">{{tosLink}}</a>
      </span>
      `,
    },
    content:{
      message: 'By using our site, you acknowledge that you have read and understand our ',
      
      cookiePolicyLink: 'Cookie Policy',
      cookiePolicyHref: 'https://creed-cms.com/cookie-policy',
   
      privacyPolicyLink: 'Privacy Policy',
      privacyPolicyHref: 'https://creed-cms.com/privacy-policy',
   
      tosLink: 'Terms of Service',
      tosHref: 'https://creedcms.com/terms-and-conditions',
    }
  },
  seo: {
    description: "CREED can easily be accessed from any device and from anywhere because the Designs are extremely responsive. it was carefully developed to cover all areas of church management, including receive payment for tithe, offering, donation and raising funds for events. Use on any device, Send Automatic Birthday and Anniversary Wishes.",
    imageurl: "https://creedcms.com/assets/img/banner/banner2.jpg"

  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
