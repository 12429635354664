<!-- Start Payment Processing Main Banner -->
<div class="main-banner payment-processing-home">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6 col-md-12">
                        <div class="hero-content">
                            <h1>Make your money go further with PayNow</h1>
                            <p>Our passion to work hard and deliver excellent results. It could solve the needs of your customers and develop innovation.</p>

                            <a [routerLink]="['/register']" routerLinkActive="active" class="btn btn-primary">Get Started</a>
                            <a href="https://web.facebook.com/creedcms/videos/392274918158979" class="video-btn popup-youtube"> Watch Video <i class="icofont-play-alt-3"></i></a>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-12">
                        <div class="paymant-processing-image">
                            <img src="assets/img/payment-process-shape/bg.png" class="wow fadeInDown" data-wow-delay="0.6s" alt="bg">
                            <img src="assets/img/payment-process-shape/card.png" class="wow fadeInUp" data-wow-delay="0.6s" alt="card">
                            <img src="assets/img/payment-process-shape/cursor.png" class="wow fadeInLeft" data-wow-delay="0.6s" alt="cursor">
                            <img src="assets/img/payment-process-shape/dollar1.png" class="wow zoomIn" data-wow-delay="0.6s" alt="dollar1">
                            <img src="assets/img/payment-process-shape/dollar2.png" class="wow bounceIn" data-wow-delay="0.6s" alt="dollar2">
                            <img src="assets/img/payment-process-shape/laptop.png" class="wow fadeInDown" data-wow-delay="0.6s" alt="laptop">
                            <img src="assets/img/payment-process-shape/main-bg.png" class="wow zoomIn" data-wow-delay="0.6s" alt="main-bg">
                            <img src="assets/img/payment-process-shape/number.png" class="wow fadeInLeft" data-wow-delay="0.6s" alt="number">
                            <img src="assets/img/payment-process-shape/paper.png" class="wow rollIn" data-wow-delay="0.6s" alt="paper">
                            <img src="assets/img/payment-process-shape/main-pic.png" class="wow fadeInUp" data-wow-delay="0.6s" alt="main-pic">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="creative-shape"><img src="assets/img/bg3.png" alt="bg"></div>
    <div class="bg-gray shape-1"></div>
    <div class="shape1"><img src="assets/img/shape1.png" alt="img"></div>
    <div class="shape2"><img src="assets/img/shape2.png" alt="img"></div>
    <div class="shape3"><img src="assets/img/shape3.png" alt="img"></div>
    <div class="shape6"><img src="assets/img/shape6.png" alt="img"></div>
    <div class="shape8 rotateme"><img src="assets/img/shape8.svg" alt="shape"></div>
    <div class="shape9"><img src="assets/img/shape9.svg" alt="shape"></div>
</div>
<!-- End Payment Processing Main Banner -->

<!-- Start Boxes Area -->
<section class="boxes-area">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-box">
                    <div class="icon">
                        <i class="icofont-comment"></i>
                    </div>
                    <h3>Channels</h3>
                    <p>Our customers are happy because we make payment services possible from anywhere and everywhere.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-box">
                    <div class="icon">
                        <i class="icofont-like"></i>
                    </div>
                    <h3>Ussd Based</h3>
                    <p>Our services are also available on mobile by dialling *711*911# on all networks.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                <div class="single-box">
                    <div class="icon">
                        <i class="icofont-laptop"></i>
                    </div>
                    <h3>Chatbot Based</h3>
                    <p>We strive to figure out ways to help your audience grow through all social media platforms like Facebook, Telegram, Twitter and Whatsapp.</p>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Boxes Area -->

<!-- Start Features Area -->
<section class="features-area payment-processing-features ptb-100">
    <div class="container">
        <div class="row h-100 justify-content-center align-items-center">
            <div class="col-lg-5 col-md-12">
                <div class="features-holder-content">
                    <div class="section-title">
                        <h2>Upto 1 millions Daily transactions</h2>
                        <div class="bar"></div>
                        <p>Our customers are happy because we make payment services possible from anywhere and everywhere.</p>
                    </div>

                    <p>PayNow Services offers convenience in paying for goods and services on your phone or from Facebook using your mobile wallet. Our services range from payment to Churches, Insurance, Utilities, Mobile Airtime, Merchants, Bills and also
                        booking for medical services.</p>
                    <a [routerLink]="['/services']" routerLinkActive="active" class="btn btn-primary">Our Services</a>
                </div>
            </div>

            <div class="col-lg-7 col-md-12">
                <div class="row">
                    <div class="col-lg-6 col-md-6">
                        <div class="single-holder-box">
                            <img src="assets/img/payment-img1.png" alt="image">
                            <h3>Payments are Secure</h3>
                            <p>Duis hendrerit eget dolor non lora pulvinar. In conse.</p>
                        </div>

                        <div class="single-holder-box mt-30">
                            <img src="assets/img/payment-img2.png" alt="image">
                            <h3>More payments Choice</h3>
                            <p>Duis hendrerit eget dolor non lora pulvinar. In conse.</p>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="single-holder-box mt-50">
                            <img src="assets/img/payment-img3.png" alt="image">
                            <h3>Quick & Easy Process</h3>
                            <p>Duis hendrerit eget dolor non lora pulvinar. In conse.</p>
                        </div>

                        <div class="single-holder-box mt-30">
                            <img src="assets/img/payment-img4.png" alt="image">
                            <h3>FnF free Transection</h3>
                            <p>Duis hendrerit eget dolor non lora pulvinar. In conse.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="shape7"><img src="assets/img/shape7.png" alt="shape"></div>
    <div class="shape3"><img src="assets/img/shape3.png" alt="img"></div>
    <div class="bg-gray shape-1"></div>
    <div class="shape6"><img src="assets/img/shape6.png" alt="img"></div>
    <div class="shape8 rotateme"><img src="assets/img/shape8.svg" alt="shape"></div>
    <div class="shape9"><img src="assets/img/shape9.svg" alt="shape"></div>
    <div class="shape10"><img src="assets/img/shape10.svg" alt="shape"></div>
    <div class="shape11 rotateme"><img src="assets/img/shape11.svg" alt="shape"></div>
</section>
<!-- End Features Area -->

<!-- Start Work Process Area -->
<section class="saas-work-process ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>How it work?</h2>
            <div class="bar"></div>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-work-process">
                    <div class="icon">
                        <i class="icofont-unlock"></i>
                    </div>
                    <h3>Create a new account</h3>
                    <p>Morbi pharetra sapien ut mattis.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-work-process">
                    <div class="icon">
                        <i class="icofont-money"></i>
                    </div>
                    <h3>Deposite money</h3>
                    <p>Morbi pharetra sapien ut mattis.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                <div class="single-work-process">
                    <div class="icon">
                        <i class="icofont-like"></i>
                    </div>
                    <h3>Pay online services</h3>
                    <p>Morbi pharetra sapien ut mattis.</p>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Work Process Area -->

<!-- Start Services Area -->
<section class="services-area ptb-100 bg-gray">
    <div class="container">
        <div class="section-title">
            <h2>The easiest way to accept payments through your PayNow store</h2>
            <div class="bar"></div>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="icon">
                        <i class="icofont-pay"></i>
                    </div>
                    <h3>Online Payments</h3>
                    <p>Well at public scho cheeky bugger grub burke.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="icon">
                        <i class="icofont-music-note"></i>
                    </div>
                    <h3>Subscription Management</h3>
                    <p>Well at public scho cheeky bugger grub burke.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="icon">
                        <i class="icofont-optic"></i>
                    </div>
                    <h3>Optimization</h3>
                    <p>Well at public scho cheeky bugger grub burke.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="icon">
                        <i class="icofont-pie-chart"></i>
                    </div>
                    <h3>In-depth Analytics</h3>
                    <p>Well at public scho cheeky bugger grub burke.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="icon">
                        <i class="icofont-deviantart"></i>
                    </div>
                    <h3>Anti-Fraud System</h3>
                    <p>Well at public scho cheeky bugger grub burke.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="icon">
                        <i class="icofont-chart-pie-alt"></i>
                    </div>
                    <h3>Omnichannel</h3>
                    <p>Well at public scho cheeky bugger grub burke.</p>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Services Area -->

<!-- Start Overview Area -->
<section class="overview-section ptb-100">
    <div class="container">
        <div class="overview-box">
            <div class="row h-100 justify-content-center align-items-center">
                <div class="col-lg-6 col-md-6 overview-img">
                    <img src="assets/img/overview-1.png" alt="image">
                </div>

                <div class="col-lg-6 col-md-6 overview-content">
                    <div class="icon">
                        <i class="icofont-power"></i>
                    </div>
                    <h3>Register Free</h3>
                    <p>Plan ahead by day, week, or month, and see project status at a glance. Search and filter to focus in on anything form a single project to an individual person's workload.</p>

                    <ul>
                        <li>Unimited Video Call</li>
                        <li>Add Favourite contact</li>
                        <li>Camera Filter</li>
                    </ul>
                    <a href="#" class="btn btn-primary">Read More</a>
                </div>
            </div>
        </div>

        <div class="overview-box">
            <div class="row h-100 justify-content-center align-items-center">
                <div class="col-lg-6 col-md-6 overview-content">
                    <div class="icon">
                        <i class="icofont-share-alt"></i>
                    </div>
                    <h3>Configure Your Sourcing</h3>
                    <p>Plan ahead by day, week, or month, and see project status at a glance. Search and filter to focus in on anything form a single project to an individual person's workload.</p>

                    <ul>
                        <li>Unimited Video Call</li>
                        <li>Add Favourite contact</li>
                        <li>Camera Filter</li>
                    </ul>
                    <a href="#" class="btn btn-primary">Read More</a>
                </div>

                <div class="col-lg-6 col-md-6 overview-img">
                    <img src="assets/img/overview-2.png" alt="image">
                </div>
            </div>
        </div>

        <div class="overview-box">
            <div class="row h-100 justify-content-center align-items-center">
                <div class="col-lg-6 col-md-6 overview-img">
                    <img src="assets/img/overview-3.png" alt="image">
                </div>

                <div class="col-lg-6 col-md-6 overview-content">
                    <div class="icon">
                        <i class="icofont-ui-messaging"></i>
                    </div>
                    <h3>Start Receiving Payments</h3>
                    <p>Plan ahead by day, week, or month, and see project status at a glance. Search and filter to focus in on anything form a single project to an individual person's workload.</p>

                    <ul>
                        <li>Unimited Video Call</li>
                        <li>Add Favourite contact</li>
                        <li>Camera Filter</li>
                    </ul>
                    <a href="#" class="btn btn-primary">Read More</a>
                </div>
            </div>
        </div>

        <div class="overview-box">
            <div class="row h-100 justify-content-center align-items-center">
                <div class="col-lg-6 col-md-6 overview-content">
                    <div class="icon">
                        <i class="icofont-users-social"></i>
                    </div>
                    <h3>Quick & Easy Process</h3>
                    <p>Plan ahead by day, week, or month, and see project status at a glance. Search and filter to focus in on anything form a single project to an individual person's workload.</p>

                    <ul>
                        <li>Unimited Video Call</li>
                        <li>Add Favourite contact</li>
                        <li>Camera Filter</li>
                    </ul>
                    <a href="#" class="btn btn-primary">Read More</a>
                </div>

                <div class="col-lg-6 col-md-6 overview-img">
                    <img src="assets/img/overview-4.png" alt="image">
                </div>
            </div>
        </div>
    </div>

    <div class="shape7"><img src="assets/img/shape7.png" alt="shape"></div>
    <div class="shape3"><img src="assets/img/shape3.png" alt="img"></div>
    <div class="bg-gray shape-1"></div>
    <div class="shape6"><img src="assets/img/shape6.png" alt="img"></div>
    <div class="shape8 rotateme"><img src="assets/img/shape8.svg" alt="shape"></div>
    <div class="shape9"><img src="assets/img/shape9.svg" alt="shape"></div>
    <div class="shape10"><img src="assets/img/shape10.svg" alt="shape"></div>
    <div class="shape11 rotateme"><img src="assets/img/shape11.svg" alt="shape"></div>
</section>
<!-- End Overview Area -->

<!-- Start CTA Area -->
<div class="cta-area">
    <div class="container">
        <div class="row h-100 justify-content-center align-items-center">
            <div class="col-lg-7 col-md-7">
                <p>Do more on the web, with a fast and secure browser!</p>
            </div>

            <div class="col-lg-5 col-md-5 text-right">
                <a href="#" class="btn btn-primary">Take a Test Drive</a>
            </div>
        </div>
    </div>
</div>
<!-- End CTA Area -->

<!-- Start Get Started Area -->
<section class="get-started ptb-100 bg-gray">
    <div class="container">
        <div class="section-title">
            <h2>Get Started Free</h2>
            <div class="bar"></div>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>

        <div class="get-started-form">
            <form>
                <div class="form-group">
                    <input type="text" class="form-control" placeholder="Name">
                </div>
                <div class="form-group">
                    <input type="email" class="form-control" placeholder="Email">
                </div>
                <div class="form-group">
                    <input type="password" class="form-control" placeholder="Password">
                </div>
                <button type="submit" class="btn btn-primary">Sign Up</button>
                <p>or use your <a href="#">Facebook Account</a></p>
            </form>
        </div>
    </div>

    <canvas id="canvas"></canvas>
</section>
<!-- End Get Started Area -->

<!-- Start Platform Connect -->
<section class="platform-connect ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <h2>Receive Payments On Every Single Platform</h2>
            </div>

            <div class="col-lg-3 col-md-6">
                <a href="#" class="box">
                    <img src="assets/img/mailchimp.png" alt="mailchimp">

                    <h3>Mail Chimp</h3>
                    <span>Send Mail</span>

                    <i class="icofont-bubble-right"></i>
                </a>
            </div>

            <div class="col-lg-3 col-md-6">
                <a href="#" class="box">
                    <img src="assets/img/slack.png" alt="mailchimp">

                    <h3>Slack</h3>
                    <span>Messaging</span>

                    <i class="icofont-bubble-right"></i>
                </a>
            </div>

            <div class="col-lg-3 col-md-6">
                <a href="#" class="box">
                    <img src="assets/img/twitter.png" alt="mailchimp">

                    <h3>Twitter</h3>
                    <span>Twitter Feed</span>

                    <i class="icofont-bubble-right"></i>
                </a>
            </div>

            <div class="col-lg-3 col-md-6">
                <a href="#" class="box">
                    <img src="assets/img/instagram.png" alt="mailchimp">

                    <h3>Instagram</h3>
                    <span>News Feed</span>

                    <i class="icofont-bubble-right"></i>
                </a>
            </div>
        </div>
    </div>
</section>
<!-- End Platform Connect -->

<!-- Start Feedback Area -->
<section class="feedback-area ptb-100 bg-gray">
    <div class="container">
        <div class="section-title">
            <h2>Testimonials</h2>
            <div class="bar"></div>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>

        <div class="row">
            <div class="feedback-slides owl-carousel owl-theme">
                <div class="col-lg-12">
                    <div class="single-feedback">
                        <div class="client-info">
                            <img src="assets/img/client1.jpg" alt="client">
                            <h3>Alit John</h3>
                            <span>Web Developer</span>
                        </div>
                        <p>Plan ahead by day, week, or month, and see project status at a glance. Search and filter to focus in on anything form a single project individual.</p>
                    </div>
                </div>

                <div class="col-lg-12">
                    <div class="single-feedback">
                        <div class="client-info">
                            <img src="assets/img/client2.jpg" alt="client">
                            <h3>Alit John</h3>
                            <span>SEO Expert</span>
                        </div>
                        <p>Plan ahead by day, week, or month, and see project status at a glance. Search and filter to focus in on anything form a single project individual.</p>
                    </div>
                </div>

                <div class="col-lg-12">
                    <div class="single-feedback">
                        <div class="client-info">
                            <img src="assets/img/client3.jpg" alt="client">
                            <h3>Steven John</h3>
                            <span>Web Developer</span>
                        </div>
                        <p>Plan ahead by day, week, or month, and see project status at a glance. Search and filter to focus in on anything form a single project individual.</p>
                    </div>
                </div>

                <div class="col-lg-12">
                    <div class="single-feedback">
                        <div class="client-info">
                            <img src="assets/img/client4.jpg" alt="client">
                            <h3>David Warner</h3>
                            <span>Web Developer</span>
                        </div>
                        <p>Plan ahead by day, week, or month, and see project status at a glance. Search and filter to focus in on anything form a single project individual.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <svg class="svg-feedback-bottom" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="none">
        <path d="M0,70 C30,130 70,50 100,70 L100,100 0,100 Z" fill="#ffffff"/>
    </svg>
</section>
<!-- End Feedback Area -->

<app-partner></app-partner>