import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
// import { SigninService } from 'src/app/services/signin.service';
import { ApiService } from 'src/app/services/api.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { SeoService } from 'src/app/services/seo.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  log: any; app = environment.app;
  constructor(private titleService: Title, private api: ApiService, private seo: SeoService) {
    // this.titleService.setTitle('Creed - Login Page');
    this.seo.generateTags({
      title: 'Login - CREED Church Management System',
      description: environment.seo.description,
      image: environment.seo.imageurl,
      slug: 'login'
    });
  }


  ngOnInit() {
    this.resetLogin();
  }

  login() {
    this.api.post('auth',this.log).subscribe((data: any) => {
      localStorage.setItem('Creed', JSON.stringify(data.access_token));
      localStorage.setItem('Date', JSON.stringify(data.date));
      // this.msg.success('You have been Successfully logedin to CREED', 'Login');
      window.location.replace(this.app+'login?token='+data.access_token+'&date='+data.date+'&message=login successful');
      // this.router.navigate([this.returnUrl]); location.reload(true);
    }, (err: HttpErrorResponse) => {
      // this.isLoginError = true; this.res = 'Incorrect Username or Password';
      // this.msg.error('Unable to login : ' + err, 'Login UnSuccessfull');
    });
  }

  resetLogin(form?: NgForm) {
    if (form != null) { form.reset(); }
    this.log = { username: '', password: '' };
  }
}
