<!-- Start SaaS Main Banner -->
<div class="main-banner software-home">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="row h-100 justify-content-center align-items-center">
                    <div class="col-lg-6 col-md-12">
                        <div class="saas-image">
                            <img src="assets/img/saas-shape/arrow.png" class="wow fadeInDown" data-wow-delay="0.6s" alt="arrow">
                            <img src="assets/img/saas-shape/box1.png" class="wow fadeInUp" data-wow-delay="0.6s" alt="box1">
                            <img src="assets/img/saas-shape/boy1.png" class="wow fadeInLeft" data-wow-delay="0.6s" alt="boy1">
                            <img src="assets/img/saas-shape/boy2.png" class="wow zoomIn" data-wow-delay="0.6s" alt="boy2">
                            <img src="assets/img/saas-shape/boy3.png" class="wow bounceIn" data-wow-delay="0.6s" alt="boy3">
                            <img src="assets/img/saas-shape/digital-screen.png" class="wow fadeInDown" data-wow-delay="0.6s" alt="digital-screen">
                            <img src="assets/img/saas-shape/filter1.png" class="wow zoomIn" data-wow-delay="0.6s" alt="filter1">
                            <img src="assets/img/saas-shape/filter2.png" class="wow fadeInUp" data-wow-delay="0.6s" alt="filter2">
                            <img src="assets/img/saas-shape/filter3.png" class="wow rotateIn" data-wow-delay="0.6s" alt="filter3">
                            <img src="assets/img/saas-shape/girl1.png" class="wow fadeInUp" data-wow-delay="0.6s" alt="girl1">
                            <img src="assets/img/saas-shape/girl2.png" class="wow zoomIn" data-wow-delay="0.6s" alt="girl2">
                            <img src="assets/img/saas-shape/monitor.png" class="wow zoomIn" data-wow-delay="0.6s" alt="monitor">

                            <img src="assets/img/saas-shape/main-image.png" class="wow zoomIn" data-wow-delay="0.6s" alt="main-image.png">
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-12">
                        <div class="hero-content pl-4">
                            <h1>Manage your Church Data in one placeholder</h1>
                            <p>Our passion to work hard and deliver excellent results. It could solve the needs of your customers and develop innovation.</p>
                            <a [routerLink]="['/signup']" routerLinkActive="router-link-active"  class="btn btn-primary">Get Started</a>
                            <!-- <a href="https://www.youtube.com/watch?v=bk7McNUjWgw" class="video-btn popup-youtube"> Watch Video <i class="icofont-play-alt-3"></i></a> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="creative-shape"><img src="assets/img/bg5.png" alt="bg"></div>
    <div class="bg-gray shape-1"></div>
    <div class="shape1"><img src="assets/img/shape1.png" alt="img"></div>
    <div class="shape2"><img src="assets/img/shape2.png" alt="img"></div>
    <div class="shape3"><img src="assets/img/shape3.png" alt="img"></div>
    <div class="shape4 rotateme"><img src="assets/img/shape4.png" alt="img"></div>

    <!-- Bubble Animte -->
    <div class="bubble-animate">
        <div class="circle small square1"></div>
        <div class="circle small square2"></div>
        <div class="circle small square3"></div>
        <div class="circle small square4"></div>
        <div class="circle small square5"></div>
        <div class="circle medium square1"></div>
        <div class="circle medium square2"></div>
        <div class="circle medium square3"></div>
        <div class="circle medium square4"></div>
        <div class="circle medium square5"></div>
        <div class="circle large square1"></div>
        <div class="circle large square2"></div>
        <div class="circle large square3"></div>
        <div class="circle large square4"></div>
    </div>
</div>
<!-- End SaaS Main Banner -->


<!-- <app-partner></app-partner> -->


<!-- Start Features Area -->
<section class="features-area saas-features ptb-100" id="features">
    <div class="container">
        <div class="section-title">
            <h2>Our Amazing Features</h2>
            <div class="bar"></div>
            <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p> -->
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-holder-box mt-10">
                    <img src="assets/img/features/responsive-design.png" alt="image">
                    <h4>Responsive Design</h4>
                    <p>Access creed on every device; Laptops, Tablets and Mobile Phones</p>
                </div>

                <div class="single-holder-box mt-30">
                    <img src="assets/img/features/members-module.png" alt="image">
                    <h4>Members </h4>
                    <p>Use it anywhere on phone, tablet or laptop</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-holder-box mt-50">
                    <img src="assets/img/features/financial-module.png" alt="image">
                    <h4>Financial </h4>
                    <p>Equipped with a comprehensive accounting system</p>
                </div>

                <div class="single-holder-box mt-50">
                    <img src="assets/img/features/reports.png" alt="image">
                    <h4>Reports</h4>
                    <p>Generate reports on all aspect of your church</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-holder-box mt-10">
                    <img src="assets/img/features/automated-messages.png" alt="image">
                    <h4>Automated Messages</h4>
                    <p>Don’t miss important moments in your church member’s life</p>
                </div>

                <div class="single-holder-box mt-50">
                    <img src="assets/img/features/to-do-events.png" alt="image">
                    <h4>Events / Todo</h4>
                    <p>Schedule all your events in one place.</p>
                </div>
            </div>
        </div>
        <br>
        <p class="text-center">
            <!-- <a [routerLink]="['/signup']" routerLinkActive="router-link-active"  class="btn btn-fill">View more features</a> -->
            <a [routerLink]="['/features']" routerLinkActive="router-link-active"  class="btn btn-primary">View more features</a>
        </p>
    </div>

    <div class="features-inner-area ptb-100 bg-gray">
        <div class="container-fluid">
            <div class="row h-100 justify-content-center align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="features-image">
                        <img src="assets/img/app/screen/slider.png" alt="image">
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="features-inner-content">
                        <div class="features-item">
                            <div class="icon">
                                <i class="icofont-settings"></i>
                            </div>
                            <h3>No Setup Cost</h3>
                            <p>You only provide information about your church and we take care of the rest. With a 30 day trial period, you’re allowed to test the system before making any commitment.</p>
                        </div>

                        <div class="features-item">
                            <div class="icon">
                                <i class="icofont-ui-call"></i>
                            </div>
                            <h3>Swift Support</h3>
                            <p>Our technical team are always online and ready to assist you with any challenge you might face. We’re available by mail, online chat and phone.</p>
                        </div>

                        <div class="features-item">
                            <div class="icon">
                                <i class="icofont-qr-code"></i>
                            </div>
                            <h3>Branch Management</h3>
                            <p>In need of a centralized system where you can manage all your branches from? You’re in the right place. Oversee and monitor branch activities in a seamless manner.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="shape7"><img src="assets/img/shape7.png" alt="shape"></div>
    <div class="shape3"><img src="assets/img/shape3.png" alt="img"></div>
    <div class="bg-gray shape-1"></div>
    <div class="shape6"><img src="assets/img/shape6.png" alt="img"></div>
    <div class="shape8 rotateme"><img src="assets/img/shape8.svg" alt="shape"></div>
    <div class="shape9"><img src="assets/img/shape9.svg" alt="shape"></div>
    <div class="shape10"><img src="assets/img/shape10.svg" alt="shape"></div>
    <div class="shape11 rotateme"><img src="assets/img/shape11.svg" alt="shape"></div>

    <div class="bubble-animate">
        <div class="circle small square1"></div>
        <div class="circle small square2"></div>
        <div class="circle small square3"></div>
        <div class="circle small square4"></div>
        <div class="circle small square5"></div>
        <div class="circle medium square1"></div>
        <div class="circle medium square2"></div>
        <div class="circle medium square3"></div>
        <div class="circle medium square4"></div>
        <div class="circle medium square5"></div>
        <div class="circle large square1"></div>
        <div class="circle large square2"></div>
        <div class="circle large square3"></div>
        <div class="circle large square4"></div>
    </div>
</section>
<!-- End Features Area -->

<!-- Start Join Area -->
<section class="join-area ptb-100">
    <div class="container">
        <div class="row h-100 justify-content-center align-items-center">
            <div class="col-lg-6 col-md-6">
                <div class="join-img">
                    <img src="assets/img/app/register.png" alt="img">
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="join-content">
                    <span>Start 30 Days Free Trial</span>
                    <h3>Get Started</h3>
                    <p>Join the most innovative church management and payment platform.</p>
                    <a [routerLink]="['/signup']" class="btn btn-primary">Sign Up</a>
                </div>
            </div>
        </div>
    </div>

</section>
<!-- End Join Area -->

<!-- Start CTA Area -->
<div class="cta-area">
    <div class="container">
        <div class="row h-100 justify-content-center align-items-center">
            <div class="col-lg-7 col-md-7">
                <p>Visit our Magazine to get insight on church activities</p>
            </div>

            <div class="col-lg-5 col-md-5 text-right">
                <a [routerLink]="['/blogs']" routerLinkActive="router-link-active"  class="btn btn-primary">Take me there</a>
            </div>
        </div>
    </div>
</div>
<!-- End CTA Area -->

<!-- Start SAAS Tools Area -->
<section class="saas-tools ptb-100 bg-gray">
    <div class="container">
        <div class="section-title">
            <h2>Software Modules</h2>
            <div class="bar"></div>
            <p>Our various modules have been carefully designed to ease the management of every church setting.</p>
        </div>

        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="tab">
                    <ul class="tabs">
                        <li><a href="#">
                            <i class="icofont-dashboard-web"></i>
                            <br>
                            Dashboard
                        </a></li>

                        <li><a href="#">
                            <i class="icofont-inbox"></i>
                            <br>
                            Member
                        </a></li>

                        <li><a href="#">
                            <i class="icofont-ui-calendar"></i>
                            <br>
                            Payments
                        </a></li>

                        <li><a href="#">
                            <i class="icofont-copy-invert"></i>
                            <br>
                            Financials
                        </a></li>

                        <li><a href="#">
                            <i class="icofont-wrench"></i>
                            <br>
                            Alerts
                        </a></li>
                    </ul>

                    <div class="tab_content">
                        <div class="tabs_item">
                            <div class="row h-100 justify-content-center align-items-center">
                                <div class="col-lg-6 col-md-6">
                                    <div class="tabs_item_content mt-0">
                                        <h3>Dashboard</h3>
                                        <p>With its intuitive design, the dashboard lets you have useful information at a glance. Here are some information you can note on the dashboard.</p>
                                        <ul>
                                            <li><i class="icofont-ui-check"></i> Number of Members</li>
                                            <li><i class="icofont-ui-check"></i> Number of Transactions</li>
                                            <li><i class="icofont-ui-check"></i> Number of sent SMS</li>
                                            <li><i class="icofont-ui-check"></i> User Profile</li>
                                            <li><i class="icofont-ui-check"></i> Church Payment Link</li>
                                        </ul>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-6">
                                    <div class="tabs_item_img">
                                        <img src="assets/img/app/screen/dashboard.png" alt="img">
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="tabs_item">
                            <div class="row h-100 justify-content-center align-items-center">
                                <div class="col-lg-6 col-md-6">
                                    <div class="tabs_item_img">
                                        <img src="assets/img/app/screen/members.png" alt="img">
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-6">
                                    <div class="tabs_item_content mb-0">
                                        <h3>Members</h3>
                                        <p>This module helps you store your members’ data in fine details. With a grid-like design, you are able to identify each member by his/her photo. Features include;</p>
                                        <ul>
                                            <li><i class="icofont-ui-check"></i> Member Profile</li>
                                            <li><i class="icofont-ui-check"></i> Attendance</li>
                                            <li><i class="icofont-ui-check"></i> Report </li>
                                            <li><i class="icofont-ui-check"></i> Setup</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="tabs_item">
                            <div class="row h-100 justify-content-center align-items-center">
                                <div class="col-lg-6 col-md-6">
                                    <div class="tabs_item_content mt-0">
                                        <h3>Payments</h3>
                                        <p>Receive tithe, offering, pledges, donations, and raise funds by generating a payment link for your members. Features include;</p>
                                        <ul>
                                            <li><i class="icofont-ui-check"></i> Payment Dashboard</li>
                                            <li><i class="icofont-ui-check"></i> Transactions</li>
                                            <li><i class="icofont-ui-check"></i> Fund Raising</li>
                                            <li><i class="icofont-ui-check"></i> Report</li>
                                        </ul>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-6">
                                    <div class="tabs_item_img">
                                        <img src="assets/img/app/screen/payment.png" alt="img">
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="tabs_item">
                            <div class="row h-100 justify-content-center align-items-center">
                                <div class="col-lg-6 col-md-6">
                                    <div class="tabs_item_img">
                                        <img src="assets/img/app/screen/financials.png" alt="img">
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-6">
                                    <div class="tabs_item_content mb-0">
                                        <h3>Financials</h3>
                                        <p>Transactions like tithe, offering, pledges, donations, and payments of bills (Water, Electricity, Allowances etc) are all recorded and are tied to their respective ledger for reporting purposes. Features include;</p>
                                        <ul>
                                            <li><i class="icofont-ui-check"></i> CashBook/ Petty Cash</li>
                                            <li><i class="icofont-ui-check"></i> Ledger Payment Posting</li>
                                            <li><i class="icofont-ui-check"></i> DayBook / Summary</li>
                                            <li><i class="icofont-ui-check"></i> Budgeting</li>
                                            <li><i class="icofont-ui-check"></i> Reports</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="tabs_item">
                            <div class="row h-100 justify-content-center align-items-center">
                                <div class="col-lg-6 col-md-6">
                                    <div class="tabs_item_content mt-0">
                                        <h3>Sms</h3>
                                        <p>Remind church members of upcoming events with our SMS module. This Module also lets you set SMS for birthdays and Wedding Anniversaries, so you don’t miss important days in your members’ life. Features include;</p>
                                        <ul>
                                            <li><i class="icofont-ui-check"></i> Single SMS</li>
                                            <li><i class="icofont-ui-check"></i> Bulk SMS</li>
                                            <li><i class="icofont-ui-check"></i> Group/Departmental SMS</li>
                                            <li><i class="icofont-ui-check"></i> SMS Scheduling</li>
                                        </ul>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-6">
                                    <div class="tabs_item_img">
                                        <img src="assets/img/app/screen/alert.png" alt="img">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="shape1"><img src="assets/img/shape1.png" alt="img"></div>
    <div class="shape2"><img src="assets/img/shape2.png" alt="img"></div>
    <div class="shape3"><img src="assets/img/shape3.png" alt="img"></div>
    <div class="shape6"><img src="assets/img/shape6.png" alt="img"></div>
    <div class="shape8 rotateme"><img src="assets/img/shape8.svg" alt="shape"></div>


</section>
<!-- End SAAS Tools Area -->

<app-funfact></app-funfact>

<!-- Start Work Process Area -->
<section class="saas-work-process ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>How to Get Started</h2>
            <div class="bar"></div>
            <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p> -->
        </div>

        <div class="row">
            <div class="col-lg-3 col-md-6">
                <div class="single-work-process">
                    <div class="icon">
                        <i class="icofont-calendar"></i>
                    </div>
                    <h3>Sign Up</h3>
                    <p>Fill out the sign-up form and submit</p>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-work-process">
                    <div class="icon">
                        <i class="icofont-code-alt"></i>
                    </div>
                    <h3>Approval</h3>
                    <p>We’ll contact you to verify and approve your application</p>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-work-process">
                    <div class="icon">
                        <i class="icofont-server"></i>
                    </div>
                    <h3>Access</h3>
                    <p>Upon approval, you’ll be given access to the system</p>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-work-process">
                    <div class="icon">
                        <i class="icofont-check"></i>
                    </div>
                    <h3>Training</h3>
                    <p>We’ll train you and your team to make the best out of CREED</p>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Work Process Area -->

<!-- Start Pricing Area -->
<section class="pricing-area ptb-100 bg-gray" hidden>
    <div class="container">
        <div class="section-title">
            <h2>Our Packages</h2>
            <div class="bar"></div>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="pricingTable">
                    <div class="pricing-icon">
                        <i class="icofont-globe"></i>
                    </div>
                    <div class="price-Value">
                        <span class="currency">$</span>15
                    </div>
                    <span class="month">/Month</span>
                    <div class="pricingHeader">
                        <h3 class="title">Personal</h3>
                    </div>
                    <div class="pricing-content">
                        <ul>
                            <li class="active">Send 5 invoices and quotes</li>
                            <li class="active">2 Clients and Products</li>
                            <li class="active">Invoicing and Payments</li>
                            <li class="active">Enter 5 bills</li>
                            <li class="active">Data Security and Backups</li>
                            <li>Unlimited Staff Accounts</li>
                            <li>Web Booking Widget</li>
                            <li>Monthly Reports and Analytics</li>
                            <li>24/7 Support</li>
                        </ul>
                    </div>
                    <a href="#" class="btn btn-primary">Get Started</a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="pricingTable">
                    <div class="pricing-icon">
                        <i class="icofont-globe"></i>
                    </div>
                    <div class="price-Value">
                        <span class="currency">$</span>39
                    </div>
                    <span class="month">/Month</span>
                    <div class="pricingHeader">
                        <h3 class="title">Business</h3>
                    </div>
                    <div class="pricing-content">
                        <ul>
                            <li class="active">Send 5 invoices and quotes</li>
                            <li class="active">2 Clients and Products</li>
                            <li class="active">Invoicing and Payments</li>
                            <li class="active">Enter 5 bills</li>
                            <li class="active">Data Security and Backups</li>
                            <li class="active">Unlimited Staff Accounts</li>
                            <li class="active">Web Booking Widget</li>
                            <li>Monthly Reports and Analytics</li>
                            <li>24/7 Support</li>
                        </ul>
                    </div>
                    <a href="#" class="btn btn-primary">Get Started</a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                <div class="pricingTable">
                    <div class="pricing-icon">
                        <i class="icofont-globe"></i>
                    </div>
                    <div class="price-Value">
                        <span class="currency">$</span>99
                    </div>
                    <span class="month">/Month</span>
                    <div class="pricingHeader">
                        <h3 class="title">Enterprise</h3>
                    </div>
                    <div class="pricing-content">
                        <ul>
                            <li class="active">Send 5 invoices and quotes</li>
                            <li class="active">2 Clients and Products</li>
                            <li class="active">Invoicing and Payments</li>
                            <li class="active">Enter 5 bills</li>
                            <li class="active">Data Security and Backups</li>
                            <li class="active">Unlimited Staff Accounts</li>
                            <li class="active">Web Booking Widget</li>
                            <li class="active">Monthly Reports and Analytics</li>
                            <li class="active">24/7 Support</li>
                        </ul>
                    </div>
                    <a href="#" class="btn btn-primary">Get Started</a>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Pricing Area -->

<!-- Start Blog Area -->
<section class="blog-area ptb-100" hidden>
    <div class="container">
        <div class="section-title">
            <h2>Our Latest News</h2>
            <div class="bar"></div>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>

        <div class="row">
            <div class="blog-slides owl-carousel owl-theme">
                <div class="col-lg-12 col-md-12">
                    <div class="single-blog-post">
                        <a href="#" class="post-image"><img src="assets/img/blog-img1.jpg" alt="blog-image"></a>

                        <div class="blog-post-content">
                            <ul>
                                <li><i class="icofont-user-male"></i> <a href="#">Admin</a></li>
                                <li><i class="icofont-wall-clock"></i> January 23, 2019</li>
                            </ul>
                            <h3><a href="#">The Most Popular New Business Apps</a></h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi turpis massa, dapibus nec libero vitae.</p>
                            <a href="#" class="read-more-btn">Read More <i class="icofont-rounded-double-right"></i></a>
                        </div>
                    </div>
                </div>

                <div class="col-lg-12 col-md-12">
                    <div class="single-blog-post">
                        <a href="#" class="post-image"><img src="assets/img/blog-img2.jpg" alt="blog-image"></a>

                        <div class="blog-post-content">
                            <ul>
                                <li><i class="icofont-user-male"></i> <a href="#">Admin</a></li>
                                <li><i class="icofont-wall-clock"></i> January 16, 2019</li>
                            </ul>
                            <h3><a href="#">The Best Marketing Management Tools</a></h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi turpis massa, dapibus nec libero vitae.</p>
                            <a href="#" class="read-more-btn">Read More <i class="icofont-rounded-double-right"></i></a>
                        </div>
                    </div>
                </div>

                <div class="col-lg-12 col-md-12">
                    <div class="single-blog-post">
                        <a href="#" class="post-image"><img src="assets/img/blog-img3.jpg" alt="blog-image"></a>

                        <div class="blog-post-content">
                            <ul>
                                <li><i class="icofont-user-male"></i> <a href="#">Admin</a></li>
                                <li><i class="icofont-wall-clock"></i> January 14, 2019</li>
                            </ul>
                            <h3><a href="#">3 WooCommerce Plugins to Boost Sales</a></h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi turpis massa, dapibus nec libero vitae.</p>
                            <a href="#" class="read-more-btn">Read More <i class="icofont-rounded-double-right"></i></a>
                        </div>
                    </div>
                </div>

                <div class="col-lg-12 col-md-12">
                    <div class="single-blog-post">
                        <a href="#" class="post-image"><img src="assets/img/blog-img4.jpg" alt="blog-image"></a>

                        <div class="blog-post-content">
                            <ul>
                                <li><i class="icofont-user-male"></i> <a href="#">Admin</a></li>
                                <li><i class="icofont-wall-clock"></i> January 06, 2019</li>
                            </ul>
                            <h3><a href="#">CakeMail Review – Design Custom Emails</a></h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi turpis massa, dapibus nec libero vitae.</p>
                            <a href="#" class="read-more-btn">Read More <i class="icofont-rounded-double-right"></i></a>
                        </div>
                    </div>
                </div>

                <div class="col-lg-12 col-md-12">
                    <div class="single-blog-post">
                        <a href="#" class="post-image"><img src="assets/img/blog-img5.jpg" alt="blog-image"></a>

                        <div class="blog-post-content">
                            <ul>
                                <li><i class="icofont-user-male"></i> <a href="#">Admin</a></li>
                                <li><i class="icofont-wall-clock"></i> January 04, 2019</li>
                            </ul>
                            <h3><a href="#">The Most Popular New Apps in 2019</a></h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi turpis massa, dapibus nec libero vitae.</p>
                            <a href="#" class="read-more-btn">Read More <i class="icofont-rounded-double-right"></i></a>
                        </div>
                    </div>
                </div>

                <div class="col-lg-12 col-md-12">
                    <div class="single-blog-post">
                        <a href="#" class="post-image"><img src="assets/img/blog-img3.jpg" alt="blog-image"></a>

                        <div class="blog-post-content">
                            <ul>
                                <li><i class="icofont-user-male"></i> <a href="#">Admin</a></li>
                                <li><i class="icofont-wall-clock"></i> January 26, 2019</li>
                            </ul>
                            <h3><a href="#">The Fastest Growing Apps in 2019</a></h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi turpis massa, dapibus nec libero vitae.</p>
                            <a href="#" class="read-more-btn">Read More <i class="icofont-rounded-double-right"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Blog Area -->

<!-- Start Feedback Area -->
<section class="feedback-area ptb-100 bg-gray">
    <div class="container">
        <div class="section-title">
            <h2>Testimonials</h2>
            <div class="bar"></div>
            <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p> -->
        </div>

        <div class="row">
            <div class="feedback-slides owl-carousel owl-theme">
                <div class="col-lg-12">
                    <div class="single-feedback">
                        <div class="client-info">
                            <img src="assets/img/logo.png" alt="client">
                            <h3>Agape Sanctuary</h3>
                            <span>Victory Bible Church International</span>
                        </div>
                        <p>The Application has a very Attractive interface, it is User Friendly, Easy to Understand, Very Efficient, Effective and Accuracy in Calculations.</p>
                    </div>
                </div>

                <div class="col-lg-12">
                    <div class="single-feedback">
                        <div class="client-info">
                            <img src="assets/img/logo.jpg" alt="client">
                            <h3>Dunamis Center A.G</h3>
                            <span>Assemblies Of God Ghana</span>
                        </div>
                        <p>The Application has a very Attractive interface, it is User Friendly, Easy to Understand, Very Efficient, Effective and Accuracy in Calculations.</p>
                    </div>
                </div>

                <div class="col-lg-12">
                    <div class="single-feedback">
                        <div class="client-info">
                            <img src="assets/img/logo.jpg" alt="client">
                            <h3>Center Of Light, BZV</h3>
                            <span>Church of God Mission Int</span>
                        </div>
                        <p>The Application has a very Attractive interface, it is User Friendly, Easy to Understand, Very Efficient, Effective and Accuracy in Calculations.</p>
                    </div>
                </div>

                <div class="col-lg-12" hidden>
                    <div class="single-feedback">
                        <div class="client-info">
                            <img src="assets/img/client1.jpg" alt="client">
                            <h3>David Warner</h3>
                            <span>Web Developer</span>
                        </div>
                        <p>Plan ahead by day, week, or month, and see project status at a glance. Search and filter to focus in on anything form a single project individual.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <svg class="svg-feedback-bottom" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="none">
        <path d="M0,70 C30,130 70,50 100,70 L100,100 0,100 Z" fill="#ffffff"/>
    </svg>
</section>
<!-- End Feedback Area -->

<!-- <app-partner></app-partner> -->
