<!-- Start Page Title Area -->
<section class="page-title-banner">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <h2>Creed Amazing Features</h2>
            </div>
        </div>
    </div>

    <div class="shape1"><img src="assets/img/shape1.png" alt="img"></div>
    <div class="shape2"><img src="assets/img/shape2.png" alt="img"></div>
    <div class="shape3"><img src="assets/img/shape3.png" alt="img"></div>
    <div class="shape6"><img src="assets/img/shape6.png" alt="img"></div>
    <div class="shape8 rotateme"><img src="assets/img/shape8.svg" alt="shape"></div>
    <div class="shape9"><img src="assets/img/shape9.svg" alt="shape"></div>

</section>
<!-- End Page Title Area -->

<!-- Start Features Area -->
<section class="features-area saas-features ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Core Features</h2>
            <div class="bar"></div>
            <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p> -->
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-features">
                    <div class="icon">
                        <i class="icofont-lock"></i>
                    </div>
                    <h3>Attendance Recording</h3>
                    <p>Determine who came or didn’t come to church meetings, so that administration can follow-up on those who couldn’t make it.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-features">
                    <div class="icon">
                        <i class="icofont-heart-eyes"></i>
                    </div>
                    <h3>Payment Link</h3>
                    <p>Generate and share payment link for present and virtual service attendees to contribute or give money to the church.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-features">
                    <div class="icon">
                        <i class="icofont-lock"></i>
                    </div>
                    <h3>Fund Raising Link</h3>
                    <p>You can equally generate a special link to raise funds for annual fund raising and for a project.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-features">
                    <div class="icon">
                        <i class="icofont-camera"></i>
                    </div>
                    <h3>User Manager</h3>
                    <p>Manage all your users by assigning various roles according to what you want them to do.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-features">
                    <div class="icon">
                        <i class="icofont-responsive"></i>
                    </div>
                    <h3>Schedule Sms Notification</h3>
                    <p>Set your messaging and assign the time and date it should be broadcasted.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-features">
                    <div class="icon">
                        <i class="icofont-hand-drag1"></i>
                    </div>
                    <h3>Receive Payment Globally</h3>
                    <p>With our international payment portal, you can receive payment from anywhere around the world.</p>
                </div>
            </div>
        </div>
    </div>

    <div class="shape7"><img src="assets/img/shape7.png" alt="shape"></div>
    <div class="shape3"><img src="assets/img/shape3.png" alt="img"></div>
    <div class="bg-gray shape-1"></div>
    <div class="shape6"><img src="assets/img/shape6.png" alt="img"></div>
    <div class="shape8 rotateme"><img src="assets/img/shape8.svg" alt="shape"></div>
    <div class="shape9"><img src="assets/img/shape9.svg" alt="shape"></div>
    <div class="shape10"><img src="assets/img/shape10.svg" alt="shape"></div>
    <div class="shape11 rotateme"><img src="assets/img/shape11.svg" alt="shape"></div>
</section>
<!-- End Features Area -->

<!-- Start Overview Area -->
<section class="overview-section ptb-100 bg-gray" hidden>
    <div class="container">
        <div class="section-title">
            <h2>More to Discover</h2>
            <div class="bar"></div>
            <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p> -->
        </div>

        <div class="overview-box">
            <div class="row h-100 justify-content-center align-items-center">
                <div class="col-lg-6 col-md-6 overview-img">
                    <img src="assets/img/overview-1.png" alt="image">
                </div>

                <div class="col-lg-6 col-md-6 overview-content">
                    <div class="icon">
                        <i class="icofont-power"></i>
                    </div>
                    <h3>Getting started page</h3>
                    <p>Plan ahead by day, week, or month, and see project status at a glance. Search and filter to focus in on anything form a single project to an individual person's workload.</p>

                    <ul>
                        <li>Unimited Video Call</li>
                        <li>Add Favourite contact</li>
                        <li>Camera Filter</li>
                    </ul>
                    <!-- <a href="#" class="btn btn-primary">Read More</a> -->
                </div>
            </div>
        </div>

        <div class="overview-box">
            <div class="row h-100 justify-content-center align-items-center">
                <div class="col-lg-6 col-md-6 overview-content">
                    <div class="icon">
                        <i class="icofont-anchor"></i>
                    </div>
                    <h3>Outdated comments toggling</h3>
                    <p>Plan ahead by day, week, or month, and see project status at a glance. Search and filter to focus in on anything form a single project to an individual person's workload.</p>

                    <ul>
                        <li>Unimited Video Call</li>
                        <li>Add Favourite contact</li>
                        <li>Camera Filter</li>
                    </ul>
                    <a href="#" class="btn btn-primary">Read More</a>
                </div>

                <div class="col-lg-6 col-md-6 overview-img">
                    <img src="assets/img/overview-1.png" alt="image">
                </div>
            </div>
        </div>

        <div class="overview-box">
            <div class="row h-100 justify-content-center align-items-center">
                <div class="col-lg-6 col-md-6 overview-img">
                    <img src="assets/img/overview-1.png" alt="image">
                </div>

                <div class="col-lg-6 col-md-6 overview-content">
                    <div class="icon">
                        <i class="icofont-users-social"></i>
                    </div>
                    <h3>Code review illustrations</h3>
                    <p>Plan ahead by day, week, or month, and see project status at a glance. Search and filter to focus in on anything form a single project to an individual person's workload.</p>

                    <ul>
                        <li>Unimited Video Call</li>
                        <li>Add Favourite contact</li>
                        <li>Camera Filter</li>
                    </ul>
                    <a href="#" class="btn btn-primary">Read More</a>
                </div>
            </div>
        </div>

        <div class="overview-box">
            <div class="row h-100 justify-content-center align-items-center">
                <div class="col-lg-6 col-md-6 overview-content">
                    <div class="icon">
                        <i class="icofont-ui-messaging"></i>
                    </div>
                    <h3>We provide proffesional staff</h3>
                    <p>Plan ahead by day, week, or month, and see project status at a glance. Search and filter to focus in on anything form a single project to an individual person's workload.</p>

                    <ul>
                        <li>Unimited Video Call</li>
                        <li>Add Favourite contact</li>
                        <li>Camera Filter</li>
                    </ul>
                    <a href="#" class="btn btn-primary">Read More</a>
                </div>

                <div class="col-lg-6 col-md-6 overview-img">
                    <img src="assets/img/overview-1.png" alt="image">
                </div>
            </div>
        </div>
    </div>

    <div class="shape7"><img src="assets/img/shape7.png" alt="shape"></div>
    <div class="shape3"><img src="assets/img/shape3.png" alt="img"></div>
    <div class="bg-gray shape-1"></div>
    <div class="shape6"><img src="assets/img/shape6.png" alt="img"></div>
    <div class="shape8 rotateme"><img src="assets/img/shape8.svg" alt="shape"></div>
    <div class="shape9"><img src="assets/img/shape9.svg" alt="shape"></div>
    <div class="shape10"><img src="assets/img/shape10.svg" alt="shape"></div>
    <div class="shape11 rotateme"><img src="assets/img/shape11.svg" alt="shape"></div>
</section>
<!-- End Overview Area -->


<!-- Start Overview Area -->
<section class="overview-section software-overview ptb-100">
    <div class="container">
        <!-- <div class="section-title">
            <h2>More to Discover</h2>
            <div class="bar"></div>
        </div> -->
        <div class="overview-box">
            <div class="row h-100 justify-content-center align-items-center">
                <div class="col-lg-6 col-md-6 overview-img">
                    <img src="assets/img/features/members-module.png" alt="image">
                </div>

                <div class="col-lg-6 col-md-6 overview-content">
                    <!-- <span>Database</span> -->
                    <h3>Members Module</h3>
                    <p>This module helps you store your members’ data in fine details. With a grid-like design, you are able to identify each member by his/her photo. Features include;</p>

                    <ul class="features-list">
                        <li>Member Profile</li>
                        <li>Attendance</li>
                        <li>Report</li>
                        <li>Setup</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Overview Area -->

<!-- Start Overview Area -->
<section class="overview-section software-overview ptb-100 bg-gray">
    <div class="container">
        <div class="overview-box">
            <div class="row h-100 justify-content-center align-items-center">
                <div class="col-lg-6 col-md-6 overview-content">
                    <!-- <span>Alerts</span> -->
                    <h3>Sms Module</h3>
                    <p>Remind church members of upcoming events with our SMS module. This Module also lets you set SMS for birthdays and Wedding Anniversaries, so you don’t miss important days in your members’ life. Features include;.</p>

                    <ul class="features-list">
                        <li>Single SMS</li>
                        <li>Bulk SMS</li>
                        <li>Group/Departmental SMS</li>
                        <li>SMS Scheduling</li>
                    </ul>
                </div>

                <div class="col-lg-6 col-md-6 overview-img">
                    <img src="assets/img/features/automated-messages.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Overview Area -->

<!-- Start Overview Area -->
<section class="overview-section software-overview ptb-100">
    <div class="container">
        <div class="overview-box">
            <div class="row h-100 justify-content-center align-items-center">
                <div class="col-lg-6 col-md-6 overview-img">
                    <img src="assets/img/features/financial-module.png" alt="image">
                </div>

                <div class="col-lg-6 col-md-6 overview-content">
                    <!-- <span>account</span> -->
                    <h3>Financials</h3>
                    <p>Transactions like tithe, offering, pledges, donations, and payments of bills (Water, Electricity, Allowances etc) are all recorded and are tied to their respective ledger for reporting purposes. Features include;</p>

                    <ul class="features-list">
                        <li>Petty Cash</li>
                        <li>CashBook</li>
                        <li>Ledger Posting</li>
                        <li>DayBook/ Summary</li>
                        <li>Budgeting</li>
                        <li>Chart Of Accounts</li>
                        <li>Trial Balance Report</li>
                        <li>Income/Expense Report</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Overview Area -->

<!-- Start Overview Area -->
<section class="overview-section software-overview ptb-100 bg-gray">
    <div class="container">
        <div class="overview-box">
            <div class="row h-100 justify-content-center align-items-center">
                <div class="col-lg-6 col-md-6 overview-content">
                    <!-- <span>transactions</span> -->
                    <h3>Payment</h3>
                    <p>Receive tithe, offering, pledges, donations, and raise funds by generating a payment link for your members. Features include;</p>

                    <ul class="features-list">
                        <li>Dashboard</li>
                        <li>Transactions</li>
                        <li>Fund Raising</li>
                        <li>Settlement</li>
                        <li>Report</li>
                    </ul>
                </div>

                <div class="col-lg-6 col-md-6 overview-img">
                    <img src="assets/img/app/payment-page.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Overview Area -->

<!-- Start Platform Connect -->
<section class="platform-connect ptb-100" hidden>
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <h2>Interact With Your Users On Every Single Platform</h2>
            </div>

            <div class="col-lg-3 col-md-6">
                <a href="#" class="box">
                    <img src="assets/img/mailchimp.png" alt="mailchimp">

                    <h3>Mail Chimp</h3>
                    <span>Send Mail</span>

                    <i class="icofont-bubble-right"></i>
                </a>
            </div>

            <div class="col-lg-3 col-md-6">
                <a href="#" class="box">
                    <img src="assets/img/slack.png" alt="mailchimp">

                    <h3>Slack</h3>
                    <span>Messaging</span>

                    <i class="icofont-bubble-right"></i>
                </a>
            </div>

            <div class="col-lg-3 col-md-6">
                <a href="#" class="box">
                    <img src="assets/img/twitter.png" alt="mailchimp">

                    <h3>Twitter</h3>
                    <span>Twitter Feed</span>

                    <i class="icofont-bubble-right"></i>
                </a>
            </div>

            <div class="col-lg-3 col-md-6">
                <a href="#" class="box">
                    <img src="assets/img/instagram.png" alt="mailchimp">

                    <h3>Instagram</h3>
                    <span>News Feed</span>

                    <i class="icofont-bubble-right"></i>
                </a>
            </div>
        </div>
    </div>
</section>
<!-- End Platform Connect -->
