import { Component, OnInit } from '@angular/core';
import { SeoService } from 'src/app/services/seo.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-cookie-policy',
  templateUrl: './cookie-policy.component.html',
  styleUrls: ['./cookie-policy.component.scss']
})
export class CookiePolicyComponent implements OnInit {

  constructor(private seo: SeoService) { 
    this.seo.generateTags({
      title: 'Cookie Policy - CREED Church Management System',
      description: environment.seo.description,
      image: environment.seo.imageurl,
      slug: 'cookie-policy'
    });
  }

  ngOnInit(): void {
  }

}
