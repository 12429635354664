<!-- Start Page Title Area -->
<section class="page-title-banner">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <h2>{{church.church}}, {{church.name}}</h2>
                <br>
                <h3 class="text-white">Pay tithe, offering, donnation etc.</h3>
            </div>
        </div>
    </div>

    <div class="shape1"><img src="assets/img/shape1.png" alt="img"></div>
    <div class="shape2"><img src="assets/img/shape2.png" alt="img"></div>
    <div class="shape3"><img src="assets/img/shape3.png" alt="img"></div>
    <div class="shape6"><img src="assets/img/shape6.png" alt="img"></div>
    <div class="shape8 rotateme"><img src="assets/img/shape8.svg" alt="shape"></div>
    <div class="shape9"><img src="assets/img/shape9.svg" alt="shape"></div>

</section>
<!-- End Page Title Area -->
<!-- {
    "name": "string",
  "email": "string",
  "type": "Tithe,Offering, Donation, Others",
  "mobile": "string",
  "channel": "Mtn,Vodafone, airtelTigo",
  "amount": 0,
  "reference": "string"
  } -->
<!-- Start Signup Area -->
<section class="signup-area ptb-100">
    <div class="container">
        <div class="row h-100 justify-content-center align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="signup-image">
                    <img [src]="api.imgApi + church.logo" onerror="this.src='assets/img/app/payment-page.png'" alt="image">
                </div>
                <br>
                <div class="text-center">
                    <!-- <h4>{{church.church}}, {{church.name}}</h4> -->
                    <p> {{church.mobile}} | {{church?.email}} | {{church?.postal}}</p>
                    <p> {{church.country}} | {{church.region}} | <a target="_blank" href="{{church.website}}">{{church.website}}</a></p>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="signup-form">
                    <h3 class="text-center">Make Payment</h3>
                    <form method="Post" #paymentForm="ngForm" (ngSubmit)="postPayment()">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="Name" name="name" [(ngModel)]="pay.name" required>
                                </div>
                            <!-- </div>
                            <div class="col-lg-12"> -->
                                <div class="form-group">
                                    <input type="email" class="form-control" placeholder="Email" name="email" [(ngModel)]="pay.email">
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <input type="tel" class="form-control" placeholder="Mobile number" name="mobile" [(ngModel)]="pay.mobile" required>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <input type="number" class="form-control" placeholder="Amount" name="amount" [(ngModel)]="pay.amount" required>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <select class="form-control" name="type" [(ngModel)]="pay.type" required>
                                                <option value="" selected hidden>Type</option>
                                                <option value="TITHE">TITHE</option>
                                                <option value="OFFERING">OFFERING</option>
                                                <option value="DONATION">DONATION</option>
                                                <option value="WELFARE">WELFARE</option>
                                                <option value="OTHERS">OTHERS</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <select class="form-control" name="channel" [(ngModel)]="pay.channel" required>
                                                <option value="" selected>Channel</option>
                                                <option value="MTN">MTN</option>
                                                <option value="VODAFONE">VODAFONE</option>
                                                <option value="AIRTELTIGO">AIRTELTIGO</option>
                                                <option value="CARD">CREDIT/ DEBIT CARD</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="Reference" name="reference" [(ngModel)]="pay.reference" reference>
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="text-right" *ngIf="pay.channel != 'CARD'">
                                    <button type="submit" [disabled]="paymentForm.invalid" class="btn btn-primary btn-lg">MAKE PAYMENT</button>
                                </div>
                                <!-- <div class="text-right" *ngIf="pay.channel != 'CARD'">
                                    <button type="submit" [disabled]="paymentForm.invalid">MAKE PAYMENT</button>
                                </div> -->
                                <div class="text-right" *ngIf="pay.channel === 'CARD'">
                                    <button type="button" angular4-paystack [email]="pay.email || 'info@creed-cms.com'" [currency]="'GHS'" [amount]="pay.amount * 100" [ref]="reference" [class]="'btn btn-primary btn-lg'" (paymentInit)="paymentInit()" (onClose)="paymentCancel()" (callback)="paymentDone($event)" [disabled]="paymentForm.invalid" class="btn btn-primary btn-lg">PAY With Card </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Signup Area -->
