<!-- Start Fun Facts Area -->
<section class="fun-facts-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Our Numbers</h2>
            <div class="bar"></div>
            <p></p>
        </div>
        <div class="row">
            <div class="col-lg-3 col-md-6">
                <div class="single-funfact">
                    <div class="icon">
                        <i class="icofont-users"></i>
                    </div>
                    <h3><span class="count">100</span></h3>
                    <p>Churches</p>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-funfact">
                    <div class="icon">
                        <i class="icofont-diamond"></i>
                    </div>
                    <h3><span class="count">5000</span></h3>
                    <p>Members</p>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-funfact">
                    <div class="icon">
                        <i class="icofont-thumbs-up"></i>
                    </div>
                    <h3><span class="count">15000 </span></h3>
                    <p>Transactions</p>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-funfact">
                    <div class="icon">
                        <i class="icofont-wrench"></i>
                    </div>
                    <h3><span class="count">₵1M</span></h3>
                    <p>Processed</p>
                </div>
            </div>
        </div>
    </div>

    <div class="fun-facts-inner-area">
        <div class="container-fluid">
            <div class="row h-100 justify-content-center align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="fun-facts-image">
                        <img src="assets/img/app/screen/manage-receive-payment.png" alt="image">
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="fun-facts-inner-content">
                        <h3>Manage your Church & Receive payment easily</h3>
                        <p>It is now essential for any church to embrace new technologies that enhances church growth. Every church must have relevant information about each member, and be ready to accommodate modern-minded members who has grown up with numerous technologies. Physical cash transactions are fading away, making way for electronic transactions. Which is why CREED is perfect for you.</p>
                        <ul>
                            <!-- <li>Use on any device</li>
                            <li>Send Automatic Birthday and Anniversary</li>
                            <li>Budgeting</li> -->
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Fun Facts Area -->
