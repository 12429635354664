<!-- Start Page Title Area -->
<section class="page-title-banner">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <h2>Services</h2>
            </div>
        </div>
    </div>

    <div class="shape1"><img src="assets/img/shape1.png" alt="img"></div>
    <div class="shape2"><img src="assets/img/shape2.png" alt="img"></div>
    <div class="shape3"><img src="assets/img/shape3.png" alt="img"></div>
    <div class="shape6"><img src="assets/img/shape6.png" alt="img"></div>
    <div class="shape8 rotateme"><img src="assets/img/shape8.svg" alt="shape"></div>
    <div class="shape9"><img src="assets/img/shape9.svg" alt="shape"></div>

</section>
<!-- End Page Title Area -->

<!-- Start Services Area -->
<section class="services-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="icon">
                        <i class="icofont-pay"></i>
                    </div>
                    <h3>Online Payments</h3>
                    <p>Well at public scho cheeky bugger grub burke.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="icon">
                        <i class="icofont-music-note"></i>
                    </div>
                    <h3>Subscription Management</h3>
                    <p>Well at public scho cheeky bugger grub burke.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="icon">
                        <i class="icofont-optic"></i>
                    </div>
                    <h3>Optimization</h3>
                    <p>Well at public scho cheeky bugger grub burke.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="icon">
                        <i class="icofont-pie-chart"></i>
                    </div>
                    <h3>In-depth Analytics</h3>
                    <p>Well at public scho cheeky bugger grub burke.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="icon">
                        <i class="icofont-deviantart"></i>
                    </div>
                    <h3>Anti-Fraud System</h3>
                    <p>Well at public scho cheeky bugger grub burke.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="icon">
                        <i class="icofont-chart-pie-alt"></i>
                    </div>
                    <h3>Omnichannel</h3>
                    <p>Well at public scho cheeky bugger grub burke.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="icon">
                        <i class="icofont-pay"></i>
                    </div>
                    <h3>Online Payments</h3>
                    <p>Well at public scho cheeky bugger grub burke.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="icon">
                        <i class="icofont-music-note"></i>
                    </div>
                    <h3>Subscription Management</h3>
                    <p>Well at public scho cheeky bugger grub burke.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="icon">
                        <i class="icofont-optic"></i>
                    </div>
                    <h3>Optimization</h3>
                    <p>Well at public scho cheeky bugger grub burke.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="icon">
                        <i class="icofont-pie-chart"></i>
                    </div>
                    <h3>In-depth Analytics</h3>
                    <p>Well at public scho cheeky bugger grub burke.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="icon">
                        <i class="icofont-deviantart"></i>
                    </div>
                    <h3>Anti-Fraud System</h3>
                    <p>Well at public scho cheeky bugger grub burke.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="icon">
                        <i class="icofont-chart-pie-alt"></i>
                    </div>
                    <h3>Omnichannel</h3>
                    <p>Well at public scho cheeky bugger grub burke.</p>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Services Area -->
