import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// import { ToastrModule } from 'ngx-toastr';
import { JwtModule } from '@auth0/angular-jwt';

import { ApiService } from './services/api.service';
import { AuthGuard } from './guards/auth.guard';
import { ExitpageGuard } from './guards/exitpage.guard';
import { ErrorsService } from './services/errors.service';
import { NotificationsService } from './services/notifications.service';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { LoaderInterceptor } from './interceptors/loader.interceptor';
import { ErrorsHandler } from './interceptors/errors-handler';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SaasLandingComponent } from './views/pages/saas-landing/saas-landing.component';
import { HeaderComponent } from './views/layout/header/header.component';
import { FooterComponent } from './views/layout/footer/footer.component';
import { SidebarComponent } from './views/layout/sidebar/sidebar.component';
import { LoaderComponent } from './views/layout/loader/loader.component';
import { FunfactComponent } from './views/common/funfact/funfact.component';
import { PartnerComponent } from './views/common/partner/partner.component';
import { AppLandingComponent } from './views/pages/app-landing/app-landing.component';
import { DigitalMarketingComponent } from './views/pages/digital-marketing/digital-marketing.component';
import { ProductLandingComponent } from './views/pages/product-landing/product-landing.component';
import { SoftwareLandingComponent } from './views/pages/software-landing/software-landing.component';
import { BookLandingComponent } from './views/pages/book-landing/book-landing.component';
import { StartupAgencyComponent } from './views/pages/startup-agency/startup-agency.component';
import { PaymentProcessingComponent } from './views/pages/payment-processing/payment-processing.component';
import { AboutUsComponent } from './views/pages/about-us/about-us.component';
import { FeaturesComponent } from './views/pages/features/features.component';
import { ServicesComponent } from './views/pages/services/services.component';
import { GalleryComponent } from './views/pages/gallery/gallery.component';
import { BlogComponent } from './views/pages/blog/blog.component';
import { BlogDetailsComponent } from './views/pages/blog-details/blog-details.component';
import { ContactUsComponent } from './views/pages/contact-us/contact-us.component';
import { NotFoundComponent } from './views/pages/not-found/not-found.component';
import { FaqComponent } from './views/pages/faq/faq.component';
import { ComingSoonComponent } from './views/pages/coming-soon/coming-soon.component';
import { LoginComponent } from './views/pages/login/login.component';
import { SignupComponent } from './views/pages/signup/signup.component';
import { TeamComponent } from './views/pages/team/team.component';
import { PricingComponent } from './views/pages/pricing/pricing.component';
import { ProjectsComponent } from './views/pages/projects/projects.component';
import { HomeComponent } from './views/pages/home/home.component';
import { PaymentComponent } from './views/pages/payment/payment.component';
import { CreedPaymentProcessingComponent } from './views/pages/creed-payment-processing/creed-payment-processing.component';
import { environment } from 'src/environments/environment';
import { Angular4PaystackModule } from 'angular4-paystack';
import { NgcCookieConsentModule, NgcCookieConsentConfig } from 'ngx-cookieconsent';
import { PrivacyPolicyComponent } from './views/pages/privacy-policy/privacy-policy.component';
import { CookiePolicyComponent } from './views/pages/cookie-policy/cookie-policy.component';
import { TermsAndConditionsComponent } from './views/pages/terms-and-conditions/terms-and-conditions.component';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAnalyticsModule, ScreenTrackingService } from '@angular/fire/analytics';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { MemberComponent } from './views/pages/member/member.component';

export function tokenGetter() {
  return JSON.parse(localStorage.getItem('Creed'));
}

const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    domain: window.location.hostname // or 'your.domain.com' // it is mandatory to set a domain, for cookies to work properly (see https://goo.gl/S2Hy2A)
  },
  palette: {
    popup: {
      background: '#000'
    },
    button: {
      background: '#9a0002'
    }
  },
  theme: 'edgeless',
  type: 'opt-out',
  layout: 'my-custom-layout',
  layouts: {
    "my-custom-layout": '{{messagelink}}{{compliance}}'
  },
  elements:{
    messagelink: `
    <span id="cookieconsent:desc" class="cc-message">{{message}} 
      <a aria-label="learn more about cookies" tabindex="0" class="cc-link" href="{{cookiePolicyHref}}" target="_blank">{{cookiePolicyLink}}</a>, 
      <a aria-label="learn more about our privacy policy" tabindex="1" class="cc-link" href="{{privacyPolicyHref}}" target="_blank">{{privacyPolicyLink}}</a> and our 
      <a aria-label="learn more about our terms of service" tabindex="2" class="cc-link" href="{{tosHref}}" target="_blank">{{tosLink}}</a>
    </span>
    `,
  },
  content:{
    message: 'By using our site, you acknowledge that you have read and understand our ',
    
    cookiePolicyLink: 'Cookie Policy',
    cookiePolicyHref: '/cookie-policy',
 
    privacyPolicyLink: 'Privacy Policy',
    privacyPolicyHref: '/privacy-policy',
 
    tosLink: 'Terms of Service',
    tosHref: '/terms-and-conditions',
  }
};

@NgModule({
  declarations: [
    AppComponent,
    SaasLandingComponent,
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    LoaderComponent,
    FunfactComponent,
    PartnerComponent,
    AppLandingComponent,
    DigitalMarketingComponent,
    ProductLandingComponent,
    SoftwareLandingComponent,
    BookLandingComponent,
    StartupAgencyComponent,
    PaymentProcessingComponent,
    AboutUsComponent,
    FeaturesComponent,
    ServicesComponent,
    GalleryComponent,
    BlogComponent,
    BlogDetailsComponent,
    ContactUsComponent,
    NotFoundComponent,
    FaqComponent,
    ComingSoonComponent,
    LoginComponent,
    SignupComponent,
    TeamComponent,
    PricingComponent,
    ProjectsComponent,
    HomeComponent,
    PaymentComponent,
    CreedPaymentProcessingComponent,
    PrivacyPolicyComponent,
    CookiePolicyComponent,
    TermsAndConditionsComponent,
    MemberComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    CommonModule,
    Angular4PaystackModule.forRoot(environment.paystack),
    JwtModule.forRoot({
      config: {
        tokenGetter,
      }
    }),
    NgcCookieConsentModule.forRoot(cookieConfig),
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAnalyticsModule,
    AngularFirestoreModule
  ],
  providers: [ 
    ApiService, AuthGuard, ExitpageGuard, ErrorsService, NotificationsService,
    ScreenTrackingService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    // { provide: ErrorHandler, useClass: ErrorsHandler }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
