import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-creed-payment-processing',
  templateUrl: './creed-payment-processing.component.html',
  styleUrls: ['./creed-payment-processing.component.scss']
})
export class CreedPaymentProcessingComponent implements OnInit {

  church: any; pay: any; url = environment;
  constructor() { }

  ngOnInit(): void {
    this.init();
  }

  postPayment(){

  }
  init() {
    this.pay = { church: this.church.code, type: '', name: '', email: '', source: 'Payment Link', mobile: '', channel: '', amount: '', currency: 'GH¢', reference: '', date: new Date }
  }

}
