<!-- Start Page Title Area -->
<section class="page-title-banner">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <h2>Pricing</h2>
            </div>
        </div>
    </div>

    <div class="shape1"><img src="assets/img/shape1.png" alt="img"></div>
    <div class="shape2"><img src="assets/img/shape2.png" alt="img"></div>
    <div class="shape3"><img src="assets/img/shape3.png" alt="img"></div>
    <div class="shape6"><img src="assets/img/shape6.png" alt="img"></div>
    <div class="shape8 rotateme"><img src="assets/img/shape8.svg" alt="shape"></div>
    <div class="shape9"><img src="assets/img/shape9.svg" alt="shape"></div>

</section>
<!-- End Page Title Area -->

<!-- Start Pricing Area -->
<section class="pricing-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="pricingTable">
                    <div class="pricing-icon">
                        <i class="icofont-globe"></i>
                    </div>
                    <div class="price-Value">
                        <span class="currency">$</span>15
                    </div>
                    <span class="month">/Month</span>
                    <div class="pricingHeader">
                        <h3 class="title">Personal</h3>
                    </div>
                    <div class="pricing-content">
                        <ul>
                            <li class="active">Send 5 invoices and quotes</li>
                            <li class="active">2 Clients and Products</li>
                            <li class="active">Invoicing and Payments</li>
                            <li class="active">Enter 5 bills</li>
                            <li class="active">Data Security and Backups</li>
                            <li>Unlimited Staff Accounts</li>
                            <li>Web Booking Widget</li>
                            <li>Monthly Reports and Analytics</li>
                            <li>24/7 Support</li>
                        </ul>
                    </div>
                    <a href="#" class="btn btn-primary">Get Started</a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="pricingTable">
                    <div class="pricing-icon">
                        <i class="icofont-globe"></i>
                    </div>
                    <div class="price-Value">
                        <span class="currency">$</span>39
                    </div>
                    <span class="month">/Month</span>
                    <div class="pricingHeader">
                        <h3 class="title">Business</h3>
                    </div>
                    <div class="pricing-content">
                        <ul>
                            <li class="active">Send 5 invoices and quotes</li>
                            <li class="active">2 Clients and Products</li>
                            <li class="active">Invoicing and Payments</li>
                            <li class="active">Enter 5 bills</li>
                            <li class="active">Data Security and Backups</li>
                            <li class="active">Unlimited Staff Accounts</li>
                            <li class="active">Web Booking Widget</li>
                            <li>Monthly Reports and Analytics</li>
                            <li>24/7 Support</li>
                        </ul>
                    </div>
                    <a href="#" class="btn btn-primary">Get Started</a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                <div class="pricingTable">
                    <div class="pricing-icon">
                        <i class="icofont-globe"></i>
                    </div>
                    <div class="price-Value">
                        <span class="currency">$</span>99
                    </div>
                    <span class="month">/Month</span>
                    <div class="pricingHeader">
                        <h3 class="title">Enterprise</h3>
                    </div>
                    <div class="pricing-content">
                        <ul>
                            <li class="active">Send 5 invoices and quotes</li>
                            <li class="active">2 Clients and Products</li>
                            <li class="active">Invoicing and Payments</li>
                            <li class="active">Enter 5 bills</li>
                            <li class="active">Data Security and Backups</li>
                            <li class="active">Unlimited Staff Accounts</li>
                            <li class="active">Web Booking Widget</li>
                            <li class="active">Monthly Reports and Analytics</li>
                            <li class="active">24/7 Support</li>
                        </ul>
                    </div>
                    <a href="#" class="btn btn-primary">Get Started</a>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Pricing Area -->