import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { Observable } from 'rxjs';
// import 'rxjs/add/operator/catch';
// import 'rxjs/add/observable/of';
import { ApiService } from './api.service';

@Injectable({
	providedIn: 'root'
})
export class Resolver implements Resolve<Observable<any[]>> {

  constructor(
    private router: Router,
    public api: ApiService
  ) {}

  // Resolver
  resolve(route: ActivatedRouteSnapshot): Observable<any[]> {
    console.log(route.params.slug);
    return this.api.get('website/church/');
  }
}

@Injectable({
	providedIn: 'root'
})
export class ChurchsResolver implements Resolve<Observable<any[]>> {

  constructor(
    private router: Router,
    public api: ApiService
  ) {}

  // Resolver
  resolve(route: ActivatedRouteSnapshot): Observable<any[]> {
    // console.log(route.params.slug);
    return this.api.get('website/church');
  }
}

@Injectable({
	providedIn: 'root'
})
export class ChurchResolver implements Resolve<Observable<any[]>> {
  
  public order: any = {};

  constructor(
    private router: Router,
    public api: ApiService
  ) {}

  // Resolver
  resolve(route: ActivatedRouteSnapshot): Observable<any> {
    console.log(route.params.slug);
    return this.api.get('website/church/'+route.params.slug);
  }
}

@Injectable({
	providedIn: 'root'
})
export class FundRaisingsResolver implements Resolve<Observable<any[]>> {
  
  // public products: any = [];

  constructor(
    public api: ApiService
  ) {}

  // Resolver
  resolve(route: ActivatedRouteSnapshot): Observable<any[]> {
    // await new Promise(resolve => setTimeout(resolve,4000)); 
    console.log('fetching products');
    return this.api.get('website/fundraising');
  }
}


@Injectable({
	providedIn: 'root'
})
export class FundRaisingResolver implements Resolve<Observable<any>> {
  
  public order: any = {};

  constructor(
    private router: Router,
    public api: ApiService
  ) {}

  // Resolver
  resolve(route: ActivatedRouteSnapshot): Observable<any> {
    console.log(route.params.slug);
    return this.api.get('website/fundraising/'+route.params.slug);
  }
}


export class BlogsResolver implements Resolve<Observable<any[]>> {

  constructor(
    public api: ApiService
  ) {}

  // Resolver
  resolve(route: ActivatedRouteSnapshot): Observable<any[]> {
    // await new Promise(resolve => setTimeout(resolve,4000)); 
    console.log('fetching products');
    return this.api.get('website/blog');
  }
}


@Injectable({
	providedIn: 'root'
})
export class BlogResolver implements Resolve<Observable<any>> {
  
  public order: any = {};

  constructor(
    private router: Router,
    public api: ApiService
  ) {}

  // Resolver
  resolve(route: ActivatedRouteSnapshot): Observable<any> {
    console.log(route.params.slug);
    return this.api.get('website/blog/'+route.params.slug);
  }
}



