import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SaasLandingComponent } from './views/pages/saas-landing/saas-landing.component';
import { AppLandingComponent } from './views/pages/app-landing/app-landing.component';
import { DigitalMarketingComponent } from './views/pages/digital-marketing/digital-marketing.component';
import { ProductLandingComponent } from './views/pages/product-landing/product-landing.component';
import { SoftwareLandingComponent } from './views/pages/software-landing/software-landing.component';
import { BookLandingComponent } from './views/pages/book-landing/book-landing.component';
import { StartupAgencyComponent } from './views/pages/startup-agency/startup-agency.component';
import { PaymentProcessingComponent } from './views/pages/payment-processing/payment-processing.component';
import { AboutUsComponent } from './views/pages/about-us/about-us.component';
import { FeaturesComponent } from './views/pages/features/features.component';
import { ServicesComponent } from './views/pages/services/services.component';
import { GalleryComponent } from './views/pages/gallery/gallery.component';
import { TeamComponent } from './views/pages/team/team.component';
import { PricingComponent } from './views/pages/pricing/pricing.component';
import { ProjectsComponent } from './views/pages/projects/projects.component';
import { BlogComponent } from './views/pages/blog/blog.component';
import { BlogDetailsComponent } from './views/pages/blog-details/blog-details.component';
import { ContactUsComponent } from './views/pages/contact-us/contact-us.component';
import { FaqComponent } from './views/pages/faq/faq.component';
import { ComingSoonComponent } from './views/pages/coming-soon/coming-soon.component';
import { LoginComponent } from './views/pages/login/login.component';
import { SignupComponent } from './views/pages/signup/signup.component';
import { NotFoundComponent } from './views/pages/not-found/not-found.component';
import { HomeComponent } from './views/pages/home/home.component';
import { PaymentComponent } from './views/pages/payment/payment.component';
import { Resolver, ChurchsResolver, ChurchResolver } from './services/resolver.service';
import { CreedPaymentProcessingComponent } from './views/pages/creed-payment-processing/creed-payment-processing.component';
import { PrivacyPolicyComponent } from './views/pages/privacy-policy/privacy-policy.component';
import { CookiePolicyComponent } from './views/pages/cookie-policy/cookie-policy.component';
import { TermsAndConditionsComponent } from './views/pages/terms-and-conditions/terms-and-conditions.component';
import { MemberComponent } from './views/pages/member/member.component';



const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  // { path: 'default', component: SaasLandingComponent },
  // { path: 'app-landing', component: AppLandingComponent },
  // { path: 'digital-marketing', component: DigitalMarketingComponent },
  // { path: 'product-landing', component: ProductLandingComponent },
  // { path: 'software-landing', component: SoftwareLandingComponent },
  { path: 'book-landing', component: BookLandingComponent },
  // { path: 'startup-agency', component: StartupAgencyComponent },
  // { path: 'payment-processing', component: PaymentProcessingComponent },
  { path: 'home', component: HomeComponent },
  { path: 'about-us', component: AboutUsComponent },
  { path: 'features', component: FeaturesComponent },
  // { path: 'services', component: ServicesComponent },
  // { path: 'gallery', component: GalleryComponent },
  // { path: 'team', component: TeamComponent },
  // { path: 'pricing', component: PricingComponent },
  // { path: 'projects', component: ProjectsComponent },
  // { path: 'blogs', component: BlogComponent },
  // { path: 'blog-details', component: BlogDetailsComponent },
  { path: 'contact-us', component: ContactUsComponent },
  { path: 'faqs', component: FaqComponent },
  { path: 'coming-soon', component: ComingSoonComponent },
  { path: 'login', component: LoginComponent },
  { path: 'signup', component: SignupComponent },
  { path: 'privacy-policy', component: PrivacyPolicyComponent },
  { path: 'cookie-policy', component: CookiePolicyComponent },
  { path: 'terms-and-conditions', component: TermsAndConditionsComponent },
  { path: 'faqs', component: FaqComponent },
  { path: 'creed-mag-donnation', component: CreedPaymentProcessingComponent },
  { path: 'payment/:slug', component: PaymentComponent, resolve: { church: ChurchResolver }, runGuardsAndResolvers: 'always' },
  { path: 'member/:slug', component: MemberComponent, resolve: { church: ChurchResolver }, runGuardsAndResolvers: 'always' },
  { path: '**', component: NotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{
    initialNavigation: 'enabled',
    useHash: false,
    anchorScrolling: 'enabled',
    scrollPositionRestoration: 'enabled',
    // onSameUrlNavigation: 'reload'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
