import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { NotificationsService } from 'src/app/services/notifications.service';
import { SeoService } from 'src/app/services/seo.service';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit {
  church: any; pay: any; url = environment; reference: string;
  constructor(private titleService: Title, public route: ActivatedRoute, private router: Router, public api: ApiService, private seo: SeoService, private notify: NotificationsService) { 
    this.church = this.route.snapshot.data.church;
    console.log(this.church);
    if(this.church == null) { this.router.navigate(['/404']); }
    this.titleService.setTitle('Payment - ' + this.church.church+' '+ this.church.name);
    this.seo.generateTags({
      title: 'Payment - '+ this.church.church+', '+ this.church.name,
      description: 'Read about Creed CMS',
      image: this.url.imageUrl + this.church.logo || this.url.app + 'assets/img/app/payment-page.png'
    });
  }

  ngOnInit(): void {
    this.init();
  }

  paymentInit() {
    console.log('Payment initialized');
  }

  paymentDone(ref: any) {
    const status = 'Payment successfull';
    console.log(status, ref);
    this.pay.tranid = ref.trxref; this.pay.transaction_no = ref.transaction; this.pay.transaction = ref.transaction;
    this.api.post('website/CardPayment',this.pay).subscribe((data: any) => {
      console.info(data);
      this.notify.success({message: 'Request Sent, Kindly Confirm request from your phone'});
      this.init();
    },(err)=>{
      this.reference = `CREED-${Math.ceil(Math.random() * 10e10)}`;
    });
  }

  paymentCancel() {
    console.log('payment failed');
    this.reference = `CREED-${Math.ceil(Math.random() * 10e10)}`;
  }

  postPayment() {
    // this.showMsg('success', 'Welcome', 'You Are Welcome @' + this.user.FullName);
    this.api.post('website/payment',this.pay).subscribe((data: any) => {
      console.info(data);
      this.notify.success({message: 'Request Sent, Kindly Confirm request from your phone'});
      this.init();
    });
  }

  init() {
    this.pay = { church: this.church.code, type: '', name: '', email: '', source: 'Payment Link', mobile: '', channel: '', amount: '', currency: 'GH¢', reference: '', date: new Date };
    this.reference = `CREED-${Math.ceil(Math.random() * 10e10)}`;
  }
}
