<!-- Start Page Title Area -->
<section class="page-title-banner">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="signup-image">
                    <img src="assets/images/TCM-Logo-white.png" onerror="this.src='assets/img/app/payment-page.png'" alt="image">
                </div>
                <br>
                <h3 class="text-white">Make a donation or Pay subscription</h3>
            </div>
        </div>
    </div>

    <div class="shape1"><img src="assets/img/shape1.png" alt="img"></div>
    <div class="shape2"><img src="assets/img/shape2.png" alt="img"></div>
    <div class="shape3"><img src="assets/img/shape3.png" alt="img"></div>
    <div class="shape6"><img src="assets/img/shape6.png" alt="img"></div>
    <div class="shape8 rotateme"><img src="assets/img/shape8.svg" alt="shape"></div>
    <div class="shape9"><img src="assets/img/shape9.svg" alt="shape"></div>

</section>
<!-- End Page Title Area -->
<!-- {
    "name": "string",
  "email": "string",
  "type": "Tithe,Offering, Donation, Others",
  "mobile": "string",
  "channel": "Mtn,Vodafone, airtelTigo",
  "amount": 0,
  "reference": "string"
  } -->
<!-- Start Signup Area -->
<section class="signup-area ptb-100">
    <div class="container">
        <div class="row h-100 justify-content-center align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="signup-image">
                    <img src="assets/images/TCM-Logo-Colour.png" onerror="this.src='assets/img/app/payment-page.png'" alt="image">
                </div>
                <br>
                <div class="text-center">
                    <p> 2332466353467 | mail@gmail.com | Po Box 2344</p>
                    <p> Ghana | Accra| <a target="_blank" href="#">creedmagazine.com</a></p>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="signup-form">
                    <h3 class="text-center">Make Payment</h3>
                    <form method="Post" #paymentForm="ngForm" (ngSubmit)="postPayment()">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="Name" name="name" [(ngModel)]="pay.name" required>
                                </div>
                                <div class="form-group">
                                    <input type="email" class="form-control" placeholder="Email" name="email" [(ngModel)]="pay.email">
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <input type="tel" class="form-control" placeholder="Mobile number" name="mobile" [(ngModel)]="pay.mobile" required>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <input type="number" class="form-control" placeholder="Amount" name="amount" [(ngModel)]="pay.amount" required>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <select class="form-control" name="type" [(ngModel)]="pay.type" required>
                                                <option value="" selected>Type</option>
                                                <option value="Donation">Donation</option>
                                                <option value="Subscription">Subscription</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <select class="form-control" name="channel" [(ngModel)]="pay.channel" required>
                                                <option value="" selected>channel</option>
                                                <option value="MTN">MTN</option>
                                                <option value="Vodafone">Vadafone</option>
                                                <option value="AirtelTigo">AirtelTigo</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="Reference" name="reference" [(ngModel)]="pay.reference" reference>
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="text-right">
                                    <button type="submit" [disabled]="paymentForm.invalid" class="btn btn-primary btn-lg">MAKE PAYMENT</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Signup Area -->
