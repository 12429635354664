import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { SeoService } from 'src/app/services/seo.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-features',
  templateUrl: './features.component.html',
  styleUrls: ['./features.component.scss']
})
export class FeaturesComponent implements OnInit {

    constructor(private seo: SeoService) {
      this.seo.generateTags({
        title: 'Features - CREED Church Management System',
        description: environment.seo.description,
        image: environment.seo.imageurl,
        slug: 'features'
      });
    }

    ngOnInit() {
    }
}
