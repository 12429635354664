<!-- Start Page Title Area -->
<section class="page-title-banner">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <h2>About Us</h2>
            </div>
        </div>
    </div>

    <div class="shape1"><img src="assets/img/shape1.png" alt="img"></div>
    <div class="shape2"><img src="assets/img/shape2.png" alt="img"></div>
    <div class="shape3"><img src="assets/img/shape3.png" alt="img"></div>
    <div class="shape6"><img src="assets/img/shape6.png" alt="img"></div>
    <div class="shape8 rotateme"><img src="assets/img/shape8.svg" alt="shape"></div>
    <div class="shape9"><img src="assets/img/shape9.svg" alt="shape"></div>

</section>
<!-- End Page Title Area -->

<!-- Start About Area -->
<section class="about-area ptb-100">
    <div class="container">
        <div class="row h-100 justify-content-center align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <div class="section-title">
                        <h2>Our process is simple; Our product is powerful.</h2>
                        <div class="bar"></div>
                        <p>CREED is a joint project between ACYST TECHNOLOGY CO. LTD. and ALIAS SOLUTIONS. Together, we’re committed to developing a system that is not only functional, but extremely relevant in our time.</p>
                    </div>
                    <p>Both companies bring unique perspectives and strength for the betterment of CREED, and we hope we’re able to solve all your church’s needs.</p>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <img src="assets/img/banner/creed.jpg" alt="image">
            </div>
        </div>
    </div>

    <div class="about-inner-area">
        <div class="container">
            <div class="row">
                <div class="col-lg-4 col-md-6">
                    <div class="about-text">
                        <h3>Mission</h3>
                        <p>To provide Easy medium for churches to manage their membership  records , events activities and payments.</p>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="about-text">
                        <h3>Vision</h3>
                        <p>To be the No. 1 church management system accross Africa.</p>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="about-text">
                        <h3>What we Value</h3>
                        <p>Our customers are happy because we make church management easy and simple from everywhere to anywhere.</p>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6" hidden>
                    <div class="about-text">
                        <h3>Contact Us</h3>
                        <p>We strive to figure out ways to help your audience grow through all social media platforms like Facebook, Telegram, Twitter and Whatsapp.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End About Area -->

<!-- Start CTA Area -->
<div class="cta-area">
    <div class="container">
        <div class="row h-100 justify-content-center align-items-center">
            <div class="col-lg-7 col-md-7">
                <p>Setup your church</p>
            </div>

            <div class="col-lg-5 col-md-5 text-right">
                <a [routerLink]="['/signup']" routerLinkActive="router-link-active"  class="btn btn-primary">Get Started</a>
            </div>
        </div>
    </div>
</div>
<!-- End CTA Area -->

<!-- Start Feedback Area -->
<section class="feedback-area ptb-100 bg-gray">
    <div class="container">
        <div class="section-title">
            <h2>Testimonials</h2>
            <div class="bar"></div>
        </div>

        <div class="row">
            <div class="feedback-slides owl-carousel owl-theme">
                <div class="col-lg-12">
                    <div class="single-feedback">
                        <div class="client-info">
                            <img src="assets/img/logo.jpg" alt="client">
                            <h3>Agape Sanctuary</h3>
                            <span>Victory Bible Church International</span>
                        </div>
                        <p>The Application has a very Attractive interface, it is User Friendly, Easy to Understand, Very Efficient, Effective and Accuracy in Calculations.</p>
                    </div>
                </div>

                <div class="col-lg-12">
                    <div class="single-feedback">
                        <div class="client-info">
                            <img src="assets/img/client2.jpg" alt="client">
                            <h3>Dunamis Center A.G</h3>
                            <span>Assemblies Of God Ghana</span>
                        </div>
                        <p>The Application has a very Attractive interface, it is User Friendly, Easy to Understand, Very Efficient, Effective and Accuracy in Calculations.</p>
                    </div>
                </div>

                <div class="col-lg-12">
                    <div class="single-feedback">
                        <div class="client-info">
                            <img src="assets/img/client3.jpg" alt="client">
                            <h3>Center Of Light, BZV</h3>
                            <span>Church of God Mission Int</span>
                        </div>
                        <p>The Application has a very Attractive interface, it is User Friendly, Easy to Understand, Very Efficient, Effective and Accuracy in Calculations.</p>
                    </div>
                </div>

                <div class="col-lg-12" hidden>
                    <div class="single-feedback">
                        <div class="client-info">
                            <img src="assets/img/client1.jpg" alt="client">
                            <h3>David Warner</h3>
                            <span>Web Developer</span>
                        </div>
                        <p>Plan ahead by day, week, or month, and see project status at a glance. Search and filter to focus in on anything form a single project individual.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <svg class="svg-feedback-bottom" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="none">
        <path d="M0,70 C30,130 70,50 100,70 L100,100 0,100 Z" fill="#ffffff"/>
    </svg>
</section>
<!-- End Feedback Area -->

<!-- <app-partner></app-partner> -->

<!-- Start Platform Connect -->
<section class="platform-connect ptb-100" hidden>
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <h2>Interact With Your Users On Every Single Platform</h2>
            </div>

            <div class="col-lg-3 col-md-6">
                <a href="#" class="box">
                    <img src="assets/img/mailchimp.png" alt="mailchimp">

                    <h3>Facebook</h3>
                    <span>Stay up to dtae</span>

                    <i class="icofont-bubble-right"></i>
                </a>
            </div>

            <div class="col-lg-3 col-md-6">
                <a href="#" class="box">
                    <img src="assets/img/slack.png" alt="mailchimp">

                    <h3>Telegram</h3>
                    <span>Messaging</span>

                    <i class="icofont-bubble-right"></i>
                </a>
            </div>

            <div class="col-lg-3 col-md-6">
                <a href="#" class="box">
                    <img src="assets/img/twitter.png" alt="mailchimp">

                    <h3>Twitter</h3>
                    <span>Twitter Feed</span>

                    <i class="icofont-bubble-right"></i>
                </a>
            </div>

            <div class="col-lg-3 col-md-6">
                <a href="#" class="box">
                    <img src="assets/img/instagram.png" alt="mailchimp">

                    <h3>Instagram</h3>
                    <span>News Feed</span>

                    <i class="icofont-bubble-right"></i>
                </a>
            </div>
        </div>
    </div>
</section>
<!-- End Platform Connect -->