<!-- Start Navbar Area -->
<header id="header">
    <div class="crake-nav {{location == '/digital-marketing' ? 'marketing-navbar' : ''}}">
        <div class="container">
            <nav class="navbar navbar-expand-md navbar-light">
                <a class="navbar-brand" routerLink="/">
                    <img src="assets/img/{{logo}}" alt="logo">
                </a>

                <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>

                <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                    <ul class="navbar-nav nav ml-auto">
                        <li class="nav-item">
                            <a routerLink="/home" routerLinkActive="active">Home</a>
                        </li>

                        <li class="nav-item">
                            <a routerLink="/features" routerLinkActive="active">Features</a>
                        </li>

                        <!-- <li>
                            <a routerLink="/blogs" routerLinkActive="active">Creed Magazine</a>
                        </li> -->

                        <li class="nav-item">
                            <a routerLink="/about-us" routerLinkActive="active">About</a>
                        </li>
                        <li class="nav-item">
                            <a routerLink="/contact-us" class="nav-link" routerLinkActive="active">Contact</a>
                        </li>
                        <li class="nav-item">
                            <a routerLink="/login" routerLinkActive="active">Sign In</a>
                        </li>
                        <!-- <li class="mr-auto others-option">
                            <a [routerLink]="['/signup']" routerLinkActive="router-link-active"  class="btn btn-primary">Get Started</a>
                        </li> -->
                    </ul>
                </div>
                <div class="mr-auto others-option">
                    <a [routerLink]="['/signup']" routerLinkActive="router-link-active"  class="btn btn-sm btn-xs btn-primary">Get Started</a>
                </div>
            </nav>
        </div>
    </div>
</header>
<!-- End Navbar Area -->

<app-sidebar></app-sidebar>

<!-- Start Search Box -->
<div id="header-search" class="header-search">
    <button type="button" class="close">×</button>
    <form class="header-search-form">
        <input type="search" value="" placeholder="Type here........" />
        <button type="submit" class="btn btn-primary">Search</button>
    </form>
</div>
<!-- End Search Box -->