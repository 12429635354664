import { Component, OnInit } from '@angular/core';
import { SeoService } from 'src/app/services/seo.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.scss']
})
export class TermsAndConditionsComponent implements OnInit {

  constructor(private seo: SeoService) {
    this.seo.generateTags({
      title: 'Terms & Conditions - CREED Church Management System',
      description: environment.seo.description,
      image: environment.seo.imageurl,
      slug: 'terms-and-conditions'
    });
  }
  
  ngOnInit(): void {
  }

}
