import { Component, OnInit } from '@angular/core';
import { SeoService } from 'src/app/services/seo.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor(private seo: SeoService) { 
    this.seo.generateTags({
      title: 'Home - CREED Church Management System',
      description: environment.seo.description,
      image: environment.seo.imageurl,
      slug: 'home'
    });
  }

  ngOnInit() {

  }

}
