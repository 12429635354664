import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { SeoService } from 'src/app/services/seo.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {

  constructor(private seo: SeoService) { 
    this.seo.generateTags({
      title: 'Contact Us - CREED Church Management System',
      description: environment.seo.description,
      image: environment.seo.imageurl,
      slug: 'contact-us'
    });
  }

  ngOnInit() {
  }

}
